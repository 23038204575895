import { Description, Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useCallback, useState } from 'react';

import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { useOverview } from '@/pages/overview/common/utils';
import { trpcReact } from '@/utils/trpc';

interface ConfirmNewFolderDialogProps {
  isDialogOpen: boolean;
  closeDialog: () => void;
  clientMatterNumber: number;
  clientNumber: number;
}

export const ConfirmNewFolderDialog = ({
  isDialogOpen,
  closeDialog,
  clientMatterNumber,
  clientNumber,
}: ConfirmNewFolderDialogProps) => {
  const [folderName, setFolderName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [subErrorMessage, setSubErrorMessage] = useState('');

  const {
    matter: { folderConfig },
  } = useOverview();

  const { setNewlyCreatedFolder } = useDataRoom();

  const updateEmptyFolders = trpcReact.dataRoom.updateEmptyFolders.useMutation();
  const emptyFolders = folderConfig?.emptyFolders || [];
  const allFolderNames = emptyFolders.flatMap((path) =>
    path.split('/').filter((segment) => segment !== ''),
  );

  const removeErrorMessage = useCallback(() => {
    setErrorMessage('');
    setSubErrorMessage('');
  }, []);

  const createFolder = useCallback(() => {
    const emptyFolders = folderConfig?.emptyFolders || [];
    const newFolderName = folderName.charAt(0).toUpperCase() + folderName.slice(1);
    const formattedFolderName = folderName.charAt(0) === '/' ? newFolderName : '/' + newFolderName;

    if (!emptyFolders.includes(formattedFolderName)) {
      updateEmptyFolders.mutate({
        clientMatterNumber: clientMatterNumber,
        clientNumber: clientNumber,
        emptyFolders: [...emptyFolders, formattedFolderName],
      });
    }
    setNewlyCreatedFolder({ folderPath: formattedFolderName, isFolderCreated: true });
    setFolderName('');
    closeDialog();
  }, [
    closeDialog,
    folderConfig?.emptyFolders,
    folderName,
    setNewlyCreatedFolder,
    updateEmptyFolders,
    clientMatterNumber,
    clientNumber,
  ]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value.replace(/\s+/g, ' ');
      const reservedCharacters = /[<>:"/\\|?*]/;
      if (reservedCharacters.test(inputValue)) {
        setErrorMessage(`A folder name can't contain the following characters:`);
        setSubErrorMessage(`\\ / : * ? " < > | `);
      } else {
        removeErrorMessage();
        setFolderName(inputValue.trimStart());
      }
    },
    [removeErrorMessage],
  );

  const handleSubmit = useCallback(() => {
    const modifiedFolderName = folderName.charAt(0).toUpperCase() + folderName.slice(1);
    if (folderName.trim() === '') {
      setErrorMessage(`Folder name can't be blank`);
      return;
    } else if (allFolderNames.includes(modifiedFolderName)) {
      setErrorMessage('Folder name already exists');
      return;
    } else {
      removeErrorMessage();
      createFolder();
    }
  }, [allFolderNames, createFolder, folderName, removeErrorMessage]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (event.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  const handleDialogExit = useCallback(() => {
    closeDialog();
    setFolderName('');
    removeErrorMessage();
  }, [closeDialog, removeErrorMessage]);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
    >
      <DialogPanel className="flex h-fit w-[454px] flex-col items-center justify-center rounded-[5px] border-2 border-light-border bg-container-dark p-4">
        <div className="flex flex-col items-center">
          <DialogTitle className="w-[355px] text-center text-[25px] font-bold text-marveri-white">
            Create New Folder
          </DialogTitle>
          <Description className="w-[355px] pb-2 text-center text-[15px] text-marveri-white">
            Enter a new folder name
          </Description>

          <input
            id="create-folder-input"
            className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-marveri-light-gold focus:bg-white focus:outline-none"
            type="text"
            value={folderName}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoComplete="off"
          />
          {errorMessage ? (
            <div className="mt-2 flex flex-col text-center font-bold text-marveri-red">
              <span>{errorMessage}</span>
              <span>{subErrorMessage}</span>
            </div>
          ) : (
            <div></div>
          )}
          <div className="m-6 flex w-4/5 justify-evenly gap-10">
            <button
              className="h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
              onClick={handleDialogExit}
            >
              Cancel
            </button>
            <button
              className="h-[38px] w-[100px] rounded border border-dark-border bg-marveri-gold text-marveri-white hover:bg-marveri-light-gold"
              onClick={handleSubmit}
              disabled={errorMessage !== '' || folderName === ''}
            >
              Create
            </button>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
