interface CheckBoxProps<T> {
  value?: T;
  index?: number;
  checked?: boolean;
  hovered?: boolean;
  onToggle?: (value?: T, index?: number) => void;
  alwaysVisible?: boolean;
}

export const CheckBox = <T,>({
  value,
  index,
  checked,
  hovered,
  onToggle,
  alwaysVisible,
}: CheckBoxProps<T>) => {
  return (
    <div className="flex items-center justify-center">
      {hovered || checked || alwaysVisible ? (
        <input
          className="size-4 cursor-pointer appearance-none border border-light-border bg-marveri-background
  bg-contain bg-center bg-no-repeat checked:bg-[url('@/assets/images/check-icon-white.svg')]"
          type="checkbox"
          checked={!!checked}
          onChange={() => onToggle && onToggle(value, index)}
        />
      ) : (
        <div className="size-4"></div>
      )}
    </div>
  );
};
