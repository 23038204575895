import * as d3 from 'd3';
import { SimulationLinkDatum, SimulationNodeDatum } from 'd3';
import d3Menu from 'd3-context-menu';

import {
  CLUSTER_CENTER_OFFSET,
  DOCUMENT_DISTANCE_MULTIPLIER,
  DOCUMENT_NAME_OFFSET_X,
  DOCUMENT_NAME_OFFSET_Y,
  DOCUMENT_OFFSET,
  DOCUMENT_RADIUS,
  SIMULATION_ALPHA_DECAY,
  SIMULATION_VELOCITY_DECAY,
} from '@/pages/overview/redline/clustering/d3/constants';
import { Cluster, Diff } from '@/pages/overview/redline/clustering/d3/draw-cluster';
import { reduceText } from '@/pages/overview/redline/clustering/d3/utils';

type DocumentNode = SimulationNodeDatum & {
  id: string;
  distance: number;
  neighbor: { file: { name: string; pdfUrl: string } | null };
};
type LinkNode = SimulationLinkDatum<DocumentNode> & { distance: number };

type Handlers = {
  onChangeTemplate: (clusterName: string, newTemplateFile: string) => void;
  onMoveToCluster: (fileName: string[], fromCluster: string, toCluster: string) => void;
  onRemoveFromCluster: (clusterName: string, fileName: string) => void;
};

/**
 * drawDocuments is the function that draws documents inside the clusters, this
 * function must only be called after the clusters have been drawn
 * @param clusters Information of all the cluster including the cluster position  */

export const drawDocument = (clusters: Cluster[], focusedClusterId: string, handlers: Handlers) => {
  d3.select('.documents').selectAll('*').remove();
  clusters.forEach((cluster) => {
    const nodes: DocumentNode[] = [
      {
        id: cluster.templateFile.id,
        neighbor: {
          file: {
            name: cluster.templateFile.file?.name || '',
            pdfUrl: cluster.templateFile.file?.pdfUrl || '',
          },
        },
        distance: 0,
      },
      ...cluster.diffs.filter(
        (d) => d.neighbor.file?.name || '' !== cluster.templateFile.file?.name || '',
      ),
    ];

    const links: LinkNode[] = (() => {
      // Calculate the inverse of each distance and find the maximum
      const inverseDistances = cluster.diffs.map((d) => 1 - d.distance);
      const maxInverseDistance = Math.max(...inverseDistances);

      // Normalize distances if maxInverseDistance is greater than 0
      return cluster.diffs
        .filter((d) => d.neighbor.file?.name || '' !== cluster.templateFile.file?.name || '')
        .map((d, index) => {
          const normalizedDistance =
            maxInverseDistance > 0
              ? inverseDistances[index] / maxInverseDistance
              : inverseDistances[index];
          return {
            source: cluster.templateFile.id,
            target: d.id,
            distance: (normalizedDistance + 0.5) * DOCUMENT_DISTANCE_MULTIPLIER,
          };
        });
    })();

    const lnks = d3.select('.documents').append('g');
    const docs = d3.select('.documents').append('g');

    d3.forceSimulation(nodes)
      .velocityDecay(SIMULATION_VELOCITY_DECAY)
      .alphaDecay(SIMULATION_ALPHA_DECAY)
      .force(
        'center',
        d3.forceCenter(
          cluster.x! + CLUSTER_CENTER_OFFSET - DOCUMENT_OFFSET,
          cluster.y! + CLUSTER_CENTER_OFFSET - DOCUMENT_OFFSET,
        ),
      )
      .force(
        'link',
        d3
          .forceLink<DocumentNode, LinkNode>()
          .links(links)
          .id((d) => d.id)
          .distance((d) => (cluster.id === focusedClusterId ? d.distance : d.distance / 10)),
      )
      .force(
        'collide',
        d3.forceCollide(
          cluster.id === focusedClusterId ? DOCUMENT_RADIUS * 6 : DOCUMENT_RADIUS * 2,
        ),
      )
      .on('tick', () =>
        updateNodes(docs, nodes, cluster, handlers, clusters, focusedClusterId === cluster.id),
      )
      .on('end', () => cluster.id === focusedClusterId && drawLinks(lnks, links, cluster));
  });
};

const drawLinks = (
  el: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
  links: LinkNode[],
  cluster: Cluster,
) => {
  el.selectAll('line')
    .data(links)
    .join('line')
    .attr('data-cluster', cluster.id)
    .attr('x1', ({ source }: LinkNode) => (source as DocumentNode).x! + DOCUMENT_OFFSET)
    .attr('y1', ({ source }: LinkNode) => (source as DocumentNode).y! + DOCUMENT_OFFSET)
    .attr('x2', ({ target }: LinkNode) => (target as DocumentNode).x! + DOCUMENT_OFFSET)
    .attr('y2', ({ target }: LinkNode) => (target as DocumentNode).y! + DOCUMENT_OFFSET)
    .style('stroke-width', '2px')
    .style('stroke', '#D9D9D9');
};

const updateNodes = (
  el: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
  nodes: DocumentNode[],
  cluster: Cluster,
  handlers: Handlers,
  clusters: Cluster[],
  focusedCluster: boolean,
) => {
  const menu = (d: Diff) => [
    {
      title: d.distance > 0 ? `Cluster ${cluster.name}` : `Template`,
    },
    ...(d.distance > 0
      ? [
          {
            divider: true,
          },
          {
            title: 'Make Template',
            action: (d: Diff) =>
              handlers.onChangeTemplate(cluster.name, d.neighbor.file?.name || ''),
          },
          {
            title: 'Move to',
            children: clusters
              .filter((c) => c.name !== cluster.name)
              .map((c) => ({
                title: `Cluster ${c.name}`,
                action: (d: Diff) =>
                  handlers.onMoveToCluster([d.neighbor.file?.name || ''], cluster.name, c.name),
              })),
          },
          {
            title: 'Remove from Cluster',
            action: (d: Diff) =>
              handlers.onRemoveFromCluster(d.neighbor.file?.name || '', cluster.name),
          },
        ]
      : []),
  ];
  const doc = el
    .selectAll('svg')
    .data(nodes)
    .join('svg')
    .attr('data-cluster', cluster.id)
    .attr('x', (d: DocumentNode) => d.x!)
    .attr('y', (d: DocumentNode) => d.y!)
    .attr('class', 'group block')
    .on('mouseenter', function () {
      d3.selectAll('.partial-label').style('visibility', 'hidden');
      d3.select(this).selectAll('.complete-label').style('visibility', 'visible');
    })
    .on('mouseleave', function () {
      d3.selectAll('.partial-label').style('visibility', 'visible');
      d3.select(this).selectAll('.complete-label').style('visibility', 'hidden');
    });

  const text = doc
    .selectAll('text')
    .data((d: DocumentNode) => [d])
    .join('text')
    .attr('x', DOCUMENT_NAME_OFFSET_X)
    .attr('y', DOCUMENT_NAME_OFFSET_Y)
    .style('font-size', '12px')
    .style('font-weight', '700')
    .attr('fill', '#FFFFFF')
    .attr('dy', 0)
    .attr('class', () => (focusedCluster ? '' : 'hidden group-hover:block'))
    .style('pointer-events', 'none');

  text
    .selectAll('.partial-label')
    .data(
      (d: DocumentNode) => d.neighbor.file?.name.split(' ').reduce(reduceText(10, 2), ['']) || '',
    )
    .join('tspan')
    .text((d) => d)
    .attr('class', 'partial-label')
    .attr('dy', '1em')
    .attr('x', DOCUMENT_NAME_OFFSET_X)
    .attr('Y', DOCUMENT_NAME_OFFSET_Y);

  text
    .selectAll('.complete-label')
    .data(
      (d: DocumentNode) => d.neighbor.file?.name.split(' ').reduce(reduceText(10, 10), ['']) || '',
    )
    .join('tspan')
    .text((d) => d)
    .attr('class', 'complete-label')
    .style('visibility', 'hidden')
    .attr('dy', '1em')
    .attr('x', DOCUMENT_NAME_OFFSET_X)
    .attr('Y', DOCUMENT_NAME_OFFSET_Y);

  doc
    .selectAll('circle')
    .data((d: DocumentNode) => [d])
    .join('circle')
    .attr('class', 'doc')
    .attr('cx', DOCUMENT_OFFSET)
    .attr('cy', DOCUMENT_OFFSET)
    .attr('fill', (d: DocumentNode) =>
      d.neighbor.file?.name === cluster.templateFile.file?.name ? 'yellow' : '#141414',
    )
    .attr('r', DOCUMENT_RADIUS)
    .style('stroke', '#989798')
    .style('color', '#D9D9D9')
    .style('stroke-width', '4px')
    .style('align', 'center')
    .style('cursor', 'pointer')
    .on('click', d3Menu(menu));
};
