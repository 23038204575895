import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ClusterIcon from '@/assets/images/clusters.svg';
import DeleteIcon from '@/assets/images/delete-icon.png';
import DocumentIcon from '@/assets/images/documents.svg';
import MoveToIcon from '@/assets/images/move-to.svg';
import RedlineIcon from '@/assets/images/redline-icon.svg';
import { CheckBox } from '@/components/Checkbox';
import { ContextMenu } from '@/components/ContextMenu';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useContextMenu } from '@/contexts/overview/dataroom/useContextMenu';
import { useRedlinesContext } from '@/contexts/overview/redlines/utils';
import { usePresignedUrl } from '@/hooks/usePresignedUrl';
import { useOverview } from '@/pages/overview/common/utils';
import {
  Cluster as ClusterT,
  Diff as DiffType,
} from '@/pages/overview/redline/clustering/d3/draw-cluster';
import { Cluster } from '@/pages/overview/redline/clustering/listing/Cluster';
import { Diff } from '@/pages/overview/redline/clustering/listing/Diff';
import { moveToCluster, removeFromCluster } from '@/pages/overview/redline/clustering/util';
import { useRedline } from '@/pages/overview/redline/useRedline';
import { changeTemplate } from '@/pages/overview/redline/utils';
import { PdfViewer } from '@/pages/pdf-viewer/PdfViewer';

export const RedlineClusterList = () => {
  const [processing, setProcessing] = useState(false);
  const [hoverId, setHoverId] = useState<string>();
  const [dropping, setDropping] = useState<string | undefined>();
  const [focusedDiff, setFocusedDiff] = useState<DiffType | undefined>();
  const [focusedCluster, setFocusedCluster] = useState<ClusterT | undefined>();
  const [checkedFiles, setCheckedFiles] = useState<DiffType[]>([]);
  const { setAllFiles, setTemplateFileUrl, setComparingFiles } = useRedlinesContext();
  const { redlineName } = useParams();
  const navigate = useNavigate();
  const [signedUrl, setSelectedFileName, selectedFileName, resetFileUrl] = usePresignedUrl();
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [pdfViewerTitle, setPdfViewerTitle] = useState('');

  const { redline, refetch } = useRedline(redlineName);
  const { allFileDifferences } = useRedlinesContext();
  const {
    matter: {
      number: matterNumber,
      client: { number: clientNumber },
      dataRoomFiles,
    },
  } = useOverview();
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor, pointerSensor);

  const tableHeaderHeight = 300;

  const { isContextMenuVisible, setIsContextMenuVisible, position, setPosition } = useContextMenu();

  useEffect(() => {
    allFileDifferences?.clear();
  }, [allFileDifferences]);

  const handlePdfViewerClose = () => {
    setIsPdfViewerOpen(false);
    resetFileUrl();
  };

  const toggleCheckbox = (diff: DiffType) => {
    if (checkedFiles.some((checkedFile) => diff.id === checkedFile.id)) {
      setCheckedFiles(checkedFiles.filter((checkedFile) => checkedFile.id !== diff.id));
    } else {
      setCheckedFiles([...checkedFiles, diff]);
    }
  };

  // const onUploadFile = useCallback(
  //   async (files: File[]) => {
  //     if (!redline) {
  //       return;
  //     }
  //     await uploadFileToCluster({
  //       clientNumber: clientNumber,
  //       matterNumber: matterNumber,
  //       redlineName: redline.name,
  //       clusterName: uploadingToCluster,
  //       files,
  //     });
  //     refetch();
  //     setUploadingToCluster(undefined);
  //     setShowFileUploadPopup(false);
  //   },
  //   [clientNumber, matterNumber, redline, refetch, uploadingToCluster],
  // );

  const onMoveToCluster = useCallback(
    async (fileNames: string[], fromCluster: string, toCluster: string) => {
      if (!redline) return null;
      try {
        setProcessing(true);
        await moveToCluster({
          clientNumber: clientNumber,
          matterNumber: matterNumber,
          redlineName: redline.name,
          fromCluster,
          toCluster,
          fileNames,
        });
      } finally {
        setProcessing(false);
      }
      await refetch();
      setDropping(undefined);
    },
    [redline, refetch, clientNumber, matterNumber],
  );

  const onMoveTemplateToCluster = useCallback(
    async (fileName: string, fromCluster: ClusterT, toCluster: string) => {
      if (!redline) return null;
      try {
        setProcessing(true);
        const newTemplate = fromCluster.diffs.find((diff) => diff.neighbor.file?.name !== fileName);
        if (newTemplate) {
          await changeTemplate({
            clientNumber: clientNumber,
            matterNumber: matterNumber,
            redlineName: redline.name,
            clusterName: fromCluster.name,
            newTemplateFile: newTemplate.neighbor.file?.name || '',
          });
        }
        await moveToCluster({
          clientNumber: clientNumber,
          matterNumber: matterNumber,
          redlineName: redline.name,
          fromCluster: fromCluster.name,
          toCluster,
          fileNames: [fileName],
        });
      } finally {
        setProcessing(false);
      }
      await refetch();
      setDropping(undefined);
    },
    [redline, refetch, clientNumber, matterNumber],
  );

  const onRemoveFromCluster = useCallback(
    async (fileName: string, clusterName: string) => {
      if (!redline) {
        return;
      }
      try {
        setProcessing(true);
        await removeFromCluster({
          clientNumber: clientNumber,
          matterNumber: matterNumber,
          redlineName: redline.name,
          clusterName,
          fileName,
        });
      } finally {
        setProcessing(false);
      }
      refetch();
    },
    [redline, refetch, clientNumber, matterNumber],
  );

  const onChangeTemplate = useCallback(
    async (clusterName: string, newTemplateFile: string) => {
      if (!redline) return null;
      try {
        setProcessing(true);
        changeTemplate({
          clientNumber: clientNumber,
          matterNumber: matterNumber,
          redlineName: redline.name,
          clusterName,
          newTemplateFile,
        });
        refetch();
      } finally {
        setProcessing(false);
      }
    },
    [clientNumber, matterNumber, redline, refetch],
  );

  const onRedlineSelected = () => {
    if (checkedFiles.length < 2) {
      return;
    }
    const files = checkedFiles.map((cf) => cf.neighbor.file?.name || '');
    setAllFiles(files);
    setTemplateFileUrl(undefined);
    setComparingFiles(files);
    navigate(`../${redline?.name}/diff`);
  };

  const onRedlineCluster = (clusterId: string) => {
    const redliningCluster = redline?.clusters.find((c: ClusterT) => c.id === clusterId);
    if (redliningCluster) {
      const files = redliningCluster.diffs
        .filter(
          (cf) => cf.neighbor.file?.name || '' !== redliningCluster.templateFile.file?.name || '',
        )
        .map((cf) => cf.neighbor.file?.name || '');

      setComparingFiles(files);
      setAllFiles([redliningCluster.templateFile.file?.name || '', ...files]);
      setTemplateFileUrl(redliningCluster.templateFile.file?.pdfUrl || '');
      navigate(`../${redline?.name}/diff`);
    }
  };

  if (!redline || redline.redlineFiles.length === 0 || redline.clusters.length === 0) {
    return (
      <div className="flex size-full cursor-progress items-center justify-center">
        <LoadingSpinner size={20} />
      </div>
    );
  }

  const handleMouseEnter = (id: string) => {
    setHoverId(id);
    if (isContextMenuVisible) return;
  };

  const handleRightClick = (event: React.MouseEvent, cluster: ClusterT, clusterDiff: DiffType) => {
    event.preventDefault();
    event.stopPropagation();
    setFocusedCluster(cluster);
    setFocusedDiff(clusterDiff);
    setIsContextMenuVisible(true);
    setPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleClusterRightClick = (event: React.MouseEvent, cluster: ClusterT) => {
    event.preventDefault();
    setFocusedCluster(cluster);
    setFocusedDiff(undefined);
    setIsContextMenuVisible(true);
    setPosition({
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleMouseLeaveFileList = () => {
    setIsContextMenuVisible(false);
    setFocusedCluster(undefined);
    setFocusedDiff(undefined);
  };

  const onToggleAll = () => {
    const allChecked =
      redline.clusters.flatMap(({ diffs }: ClusterT) => diffs).length === checkedFiles.length;
    if (allChecked) {
      setCheckedFiles([]);
      return;
    }
    setCheckedFiles(redline.clusters.flatMap(({ diffs }: ClusterT) => diffs));
  };

  const handleDragStart = (event: DragStartEvent) => {
    const dragId = event.active.id.toString();
    const file = redline.clusters
      .flatMap(({ diffs }: ClusterT) => diffs)
      .find((file: { id: string }) => file.id == dragId);
    if (file) {
      setCheckedFiles([...checkedFiles.filter((afile) => afile.id !== file.id), file]);
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (event.over && event.over.data.current) {
      const cluster = event.over.data.current;
      const filesToMove = [];
      let sourceClusterName = '';
      for (const file of checkedFiles) {
        const currentCluster = redline.clusters.find(({ diffs }: ClusterT) =>
          diffs.find((diff) => diff.id === file.id),
        );

        if (currentCluster && currentCluster.name !== cluster.current) {
          filesToMove.push(file.neighbor.file?.name || '');
          sourceClusterName = currentCluster.name;
        }
      }

      // Call onMoveToCluster with the grouped file names
      if (filesToMove.length > 0) {
        onMoveToCluster(filesToMove, sourceClusterName, cluster.current);
      }
      setCheckedFiles([]);
      return true;
    }
  };
  const allChecked =
    redline.clusters.flatMap(({ diffs }: ClusterT) => diffs).length === checkedFiles.length;

  const handleClusterDiffClick = (selectedDifference: DiffType, fileDisplayName: string) => {
    const file = dataRoomFiles.find(
      (file) => file.displayName === selectedDifference.neighbor.file?.name || '',
    );

    if (file) {
      setSelectedFileName(file.displayName);
      setPdfViewerTitle(fileDisplayName);
    } else {
      setSelectedFileName(selectedDifference.neighbor.file?.name || '');
      setPdfViewerTitle(fileDisplayName);
    }
  };

  return (
    <>
      {/* {showFileUploadPopup && (
        <RedlineClusteringUploadFileModal
          title={
            uploadingToCluster ? `Upload to Cluster ${uploadingToCluster}` : 'Upload & Auto Cluster'
          }
          isModalOpen={true}
          onUploadFile={onUploadFile}
          onClose={() => {
            setUploadingToCluster(undefined);
            setShowFileUploadPopup(false);
          }}
        />
      )} */}
      <div
        className="flex w-full cursor-default flex-col items-center bg-marveri-background text-[12px] text-marveri-white"
        onMouseLeave={handleMouseLeaveFileList}
      >
        <div className="w-[96%]">
          <h1 className="mt-[16px] flex w-full flex-row text-[34px] font-bold">
            {redline.name} - Clusters
            {processing && (
              <span className="px-[10px]">
                <LoadingSpinner size={10} />
              </span>
            )}
          </h1>
          <div className="flex w-full items-center justify-between gap-2 py-[10px]">
            <div className="w-full"></div>
            <div
              className={`group mr-7 flex w-[320px] justify-center gap-x-2 rounded-md border border-container-hover bg-container-dark px-3 py-2 font-bold shadow-sm hover:bg-container-hover ${
                checkedFiles.length < 2 ? '[&>img]:opacity-50' : '[&>img]:opacity-100'
              } ${checkedFiles.length < 2 ? 'text-marveri-muted-silver' : 'text-marveri-white'}`}
              onClick={onRedlineSelected}
            >
              <img src={RedlineIcon} className="size-[16px]" />
              Redline Selected Documents
              <div className="absolute z-20 hidden w-fit translate-y-[38px] whitespace-nowrap rounded-[5px] border border-container-hover bg-container-dark p-[4px] text-center text-xs font-normal text-marveri-white group-hover:block">
                Run a redline of selected documents <br />
                within or among clusters. <br />
                Select documents to begin.
              </div>
            </div>
          </div>
          <div className="mb-4 flex w-full border-b border-b-light-border py-2 font-bold text-marveri-light-silver">
            <div>
              <CheckBox checked={allChecked} onToggle={onToggleAll} alwaysVisible />
            </div>
            <div className="ml-4 flex w-full gap-[50%]">
              <h1>Name</h1>
            </div>
            <div className="ml-4 flex w-[80px] gap-[50%]">
              <h1>Similarities</h1>
            </div>
          </div>
          {redline.clusters.length === 0 ? (
            <div className="flex size-full cursor-progress items-center justify-center">
              <LoadingSpinner size={24} />
            </div>
          ) : (
            <div
              className="w-full overflow-y-auto scrollbar-thin scrollbar-thumb-marveri-muted-silver"
              style={{ height: `calc(100vh - ${tableHeaderHeight}px)` }}
            >
              <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} sensors={sensors}>
                <div>
                  {redline.clusters
                    .sort((a: ClusterT, b: ClusterT) =>
                      parseInt(a.name.split(' ')[0]) > parseInt(b.name.split(' ')[0]) ? 1 : -1,
                    )
                    .map((cluster: ClusterT) => (
                      <Cluster
                        key={cluster.id}
                        cluster={cluster}
                        onRedline={onRedlineCluster}
                        onContextMenu={handleClusterRightClick}
                      >
                        {cluster.diffs
                          .sort((a, b) => b.distance - a.distance)
                          .map((clusterDifference) => (
                            <Diff
                              key={clusterDifference.id}
                              cluster={cluster}
                              diff={clusterDifference}
                              onDoubleClick={() => setIsPdfViewerOpen(true)}
                              onClick={handleClusterDiffClick}
                              onToggle={toggleCheckbox}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={() => setHoverId(undefined)}
                              onContextMenu={handleRightClick}
                              checked={checkedFiles
                                .map((file) => file.id)
                                .includes(clusterDifference.id)}
                              hovered={clusterDifference.id === hoverId}
                              dropping={clusterDifference.id === dropping}
                            />
                          ))}
                      </Cluster>
                    ))}
                  {isContextMenuVisible && (
                    <ContextMenu
                      options={[
                        ...(focusedCluster
                          ? [
                              {
                                title: 'Redline',
                                icon: RedlineIcon,
                                onClick: onRedlineSelected,
                              },
                            ]
                          : []),
                        ...(focusedDiff && focusedCluster
                          ? [
                              {
                                title: 'Move To',
                                icon: MoveToIcon,
                                options: redline.clusters
                                  .filter((c) => c?.id !== focusedCluster?.id)
                                  .map((cluster: ClusterT) => ({
                                    title: `Cluster ${cluster.name.split(' ')[0]}`,
                                    icon: ClusterIcon,
                                    onClick: () =>
                                      focusedDiff.neighbor.id === focusedCluster.templateFile.id
                                        ? onMoveTemplateToCluster(
                                            focusedDiff.neighbor.file?.name || '',
                                            focusedCluster,
                                            cluster.name,
                                          )
                                        : onMoveToCluster(
                                            [focusedDiff.neighbor.file?.name || ''],
                                            focusedCluster.name,
                                            cluster.name,
                                          ),
                                  })),
                              },
                              {
                                title: 'Make Baseline',
                                icon: DocumentIcon,
                                onClick: () =>
                                  onChangeTemplate(
                                    focusedCluster.name,
                                    focusedDiff.neighbor.file?.name || '',
                                  ),
                              },
                              {
                                title: 'Delete',
                                icon: DeleteIcon,
                                onClick: () =>
                                  onRemoveFromCluster(
                                    focusedDiff.neighbor.file?.name || '',
                                    focusedCluster.name,
                                  ),
                              },
                            ]
                          : []),
                      ]}
                      x={position.x}
                      y={position.y}
                    />
                  )}
                </div>
                <DragOverlay>
                  <div className="flex items-center gap-[8px] rounded border-2 border-light-border bg-marveri-background p-4 drop-shadow-lg">
                    <img src={DocumentIcon} alt="file-icon" className="h-[18px]" />
                    <span>{`${checkedFiles.length} file${
                      checkedFiles.length == 1 ? '' : 's'
                    } selected`}</span>
                  </div>
                </DragOverlay>
              </DndContext>
            </div>
          )}
        </div>
      </div>
      {signedUrl && isPdfViewerOpen && selectedFileName && (
        <PdfViewer
          title={pdfViewerTitle}
          fileUrl={signedUrl}
          multipleDocuments={false}
          closePdfViewer={handlePdfViewerClose}
        />
      )}
    </>
  );
};
