import { useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

interface DraggableRedlineViewFileProps {
  draggableId: string;
  draggedFileName: string;
  disabled: boolean;
}

export const DraggableRedlineViewFile = ({
  draggableId,
  children,
  draggedFileName,
  disabled,
}: PropsWithChildren<DraggableRedlineViewFileProps>) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: draggableId,
    data: {
      current: draggedFileName,
    },
    disabled: disabled,
  });

  return (
    <div className="">
      <button ref={setNodeRef} {...listeners} {...attributes} className="w-full">
        {children}
      </button>
    </div>
  );
};
