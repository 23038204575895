import { useContext } from 'react';

import { DataRoomContext } from '@/contexts/overview/dataroom/DataRoomContext';

export function useDataRoom() {
  const context = useContext(DataRoomContext);
  if (context === undefined) {
    throw new Error('useDataRoom must be used within a DataRoomContextProvider');
  }
  return context;
}
