import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ClientMatterWithDetails } from '@/common/types';
import LoadingSpinner from '@/components/LoadingSpinner';

interface ActiveTabProps {
  matter: ClientMatterWithDetails | undefined;
  toolIdentifier: string;
  to: string;
  feature: boolean;
  isPanelCollapsed: boolean;
  toolProgress: (
    startTime: Date | undefined,
    finishTime: Date | undefined,
    currentDate: Date | undefined,
  ) => number;
  getToolProcessing: (toolIdentifier: string) => {
    startTime: Date | undefined;
    finishTime: Date | undefined;
    complete: boolean;
  };
}

export const ActiveTabLink = (props: PropsWithChildren<ActiveTabProps>) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const toolProcessing = useMemo(() => {
    return props.getToolProcessing(props.toolIdentifier);
  }, [props]);

  const generalToolProgress = props.toolProgress(
    toolProcessing?.startTime,
    toolProcessing?.finishTime,
    date,
  );

  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `${toolProcessing?.complete ? 'hover:bg-[#302F30] cursor-pointer' : 'cursor-default'} ${props.feature ? 'flex' : 'hidden'} ${props.isPanelCollapsed && 'justify-center'} relative rounded-[8px] h-[60px] mb-[4px] font-bold text-[14px] items-center px-[13px] py-[20px] text-marveri-white 
         ${isActive ? 'bg-[#403E40] hover:bg-[#403E40]' : ''}`
      }
      onClick={(e) => {
        if (!toolProcessing?.complete) {
          e.preventDefault();
        }
      }}
    >
      <div
        className={`${!toolProcessing?.complete ? 'absolute left-0 top-0 z-0 h-full rounded-l-[8px]' : 'hidden'}`}
        style={{
          width: `${generalToolProgress}%`,
          backgroundColor: '#302F30',
        }}
      ></div>
      <div className={`${!toolProcessing?.complete && 'w-full opacity-50'} relative z-10 flex `}>
        {!toolProcessing?.complete && props.isPanelCollapsed ? (
          <LoadingSpinner />
        ) : (
          <>{props.children}</>
        )}
        {!toolProcessing?.complete && (
          <div className="absolute right-0">
            <LoadingSpinner />
          </div>
        )}
      </div>
    </NavLink>
  );
};
