import { useCallback, useMemo, useState } from 'react';

import RenameIcon from '@/assets/icons/rename-icon.svg';
import MarveriRename from '@/assets/images/marveri-rename.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { checkFileNameFormat, formatFileDisplay, useOverview } from '@/pages/overview/common/utils';
import { DataRoomToolDropdown } from '@/pages/overview/dataroom/content/common/DataRoomToolDropdown';
import { trpcReact } from '@/utils/trpc';

interface RenameOptionsProps {
  filesAreProcessing: boolean;
}

export const RenameOptions = ({ filesAreProcessing }: RenameOptionsProps) => {
  const [renameLoading, setRenameLoading] = useState(false);
  const { matter, user } = useOverview();
  const {
    checkedFiles,
    setCheckedFiles,
    hasCheckedFiles,
    setIsCheckAllActive,
    changeFolderCheckStatus,
  } = useDataRoom();
  const renameFileMutation = trpcReact.dataRoom.bulkUpdateDataRoomFileName.useMutation();

  const renameToMarveriFormat = useCallback(() => {
    setRenameLoading(true);
    const updates = matter.dataRoomFiles
      .filter((file) => {
        return checkedFiles.some((checkedFile) => checkedFile.name === file.name);
      })
      .map((file) => {
        return {
          name: file.name,
          newName: formatFileDisplay(file, user?.namingTemplate),
        };
      });

    renameFileMutation.mutate(
      {
        clientNumber: matter.client.number,
        clientMatterNumber: matter.number,
        updates: updates,
      },
      {
        onSettled: () => {
          setRenameLoading(false);
        },
      },
    );
    setCheckedFiles([]);
    setIsCheckAllActive(false);
    changeFolderCheckStatus(false);
  }, [
    changeFolderCheckStatus,
    checkedFiles,
    matter.client.number,
    matter.dataRoomFiles,
    matter.number,
    renameFileMutation,
    setCheckedFiles,
    setIsCheckAllActive,
    user?.namingTemplate,
  ]);

  const renameToOriginalFormat = useCallback(() => {
    setRenameLoading(true);
    const updates = checkedFiles.map((file) => {
      return {
        name: file.name,
        newName: file.originalPathname.split('/').slice(-1)[0],
      };
    });
    renameFileMutation.mutate(
      {
        clientNumber: matter.client.number,
        clientMatterNumber: matter.number,
        updates: updates,
      },
      {
        onSettled: () => {
          setRenameLoading(false);
        },
      },
    );
    setCheckedFiles([]);
    setIsCheckAllActive(false);
    changeFolderCheckStatus(false);
  }, [
    changeFolderCheckStatus,
    checkedFiles,
    matter.client.number,
    matter.number,
    renameFileMutation,
    setCheckedFiles,
    setIsCheckAllActive,
  ]);

  const shouldShowOriginalNameOption = useMemo(() => {
    return (
      checkFileNameFormat(checkedFiles) === 'MARVERI' ||
      checkFileNameFormat(checkedFiles) === 'MIXED'
    );
  }, [checkedFiles]);

  const options = useMemo(() => {
    const opts = [];
    if (shouldShowOriginalNameOption) {
      opts.push({
        title: 'Original Name',
        icon: RenameIcon,
        onClick: renameToOriginalFormat,
      });
    }

    opts.push({
      title: 'Smart Name',
      icon: MarveriRename,
      onClick: renameToMarveriFormat,
    });

    return opts;
  }, [shouldShowOriginalNameOption, renameToOriginalFormat, renameToMarveriFormat]);

  return (
    <DataRoomToolDropdown
      title="Rename"
      toolIdentifier="RENAME"
      icon={RenameIcon}
      options={options}
      testId="rename-button"
      isButtonDisabled={!hasCheckedFiles || filesAreProcessing || renameLoading}
      processing={filesAreProcessing || renameLoading}
      resizeAction={true}
    />
  );
};
