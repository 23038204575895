import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { ToolPageContent } from '@/pages/tools/content/ToolPageContent';

export const ToolPage = () => {
  return (
    <DataRoomContextProvider>
      <ToolPageContent />
    </DataRoomContextProvider>
  );
};
