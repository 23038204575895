import { useState } from 'react';

import CheckMark from '@/assets/icons/checkmark-icon.svg';
import InfoIcon from '@/assets/icons/information-icon.svg';
import MinusIcon from '@/assets/icons/minus-icon.svg';
import PlusIcon from '@/assets/icons/plus-icon-n500.svg';

interface ToolCardProps {
  toolName: string;
  toolIcon: string;
  toolIdentifier: string;
  toolTip: string;
  isToolAdded: boolean;
  addToolFunction: (isToolAdded: boolean, toolIdentifier: string) => void;
}

export const ToolCard = ({
  toolName,
  toolIcon,
  toolIdentifier,
  toolTip,
  isToolAdded,
  addToolFunction,
}: ToolCardProps) => {
  const [isInfoIconHovered, setIsInfoIconHovered] = useState(false);
  const [isToolCardHovered, setIsToolCardHovered] = useState(false);

  return (
    <div
      className={`${isToolAdded ? 'bg-[#eaeaea] hover:bg-N200' : 'border border-N600 bg-container-default hover:bg-N700'} group flex h-[136px] min-w-[220px] max-w-[320px] cursor-pointer flex-col justify-between rounded-[8px] pb-[16px] pl-[20px] pr-[16px] pt-[20px]`}
      onClick={() => addToolFunction(isToolAdded, toolIdentifier)}
      onMouseOver={() => setIsToolCardHovered(true)}
      onMouseOut={() => setIsToolCardHovered(false)}
      data-testid="tool-card"
    >
      <div className="relative flex items-start gap-[8px]" data-testid="tool-card-name">
        {!isInfoIconHovered && <img src={toolIcon} className="translate-y-[2px]" />}
        <span
          className={`${isToolAdded ? 'text-[#121112]' : 'text-marveri-white'} ${isInfoIconHovered ? 'text-[11px] text-N300' : 'text-[16px]'} w-[95%] text-left font-medium`}
        >
          {isInfoIconHovered ? toolTip : toolName}
        </span>
        {isToolAdded && <img src={CheckMark} className="absolute right-[-6px] top-[-8px]" />}
      </div>
      <div className="flex items-center justify-between">
        {isToolCardHovered ? (
          <img
            src={InfoIcon}
            className={`${isToolAdded ? 'filter-dark' : 'opacity-50 hover:opacity-100'} cursor-default`}
            onMouseEnter={() => setIsInfoIconHovered(true)}
            onMouseOut={() => setIsInfoIconHovered(false)}
          />
        ) : (
          <div></div>
        )}
        <div
          className={`${isToolAdded ? 'bg-N200 group-hover:bg-N300' : 'bg-N700 group-hover:bg-[#424242]'} flex size-[32px] items-center justify-center rounded-[34px]`}
          data-testid="tool-card-add-or-remove-tool"
        >
          {isToolAdded ? (
            <img src={MinusIcon} />
          ) : (
            <img src={PlusIcon} className="group-hover:filter-hover-filter" />
          )}
        </div>
      </div>
    </div>
  );
};
