import { useDroppable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

interface DroppableProps {
  droppableId: string;
}

export const Droppable = ({ droppableId, children }: PropsWithChildren<DroppableProps>) => {
  const { isOver, setNodeRef } = useDroppable({
    id: droppableId,
  });
  const borderStyle = isOver ? 'border border-dashed border-marveri-light-gold' : '';
  return (
    <div ref={setNodeRef} className={borderStyle}>
      {children}
    </div>
  );
};
