import { useContext } from 'react';

import { RedlineContext } from '@/contexts/overview/redlines/RedLinesContext';

export function useRedlinesContext() {
  const context = useContext(RedlineContext);
  if (context === undefined) {
    throw new Error('useRedlines must be used within a DataRoomContextProvider');
  }
  return context;
}
