export const reduceText =
  (lineLength: number, numberOfLines: number) => (lines: string[], char: string) => {
    const lastLine = lines[lines.length - 1];
    if (lastLine.length < lineLength) {
      lines[lines.length - 1] = lastLine + char;
      return lines;
    }
    if (lines.length === numberOfLines) {
      if (!lastLine.endsWith('...')) {
        lines[lines.length - 1] = lastLine + '...';
      }
      return lines;
    }
    lines[lines.length] = char;
    return lines;
  };
