import { Data, useDroppable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

interface DroppableProps<T> {
  droppableId: string;
  data: Data<T>;
}

export const Droppable = <T,>({
  droppableId,
  children,
  data,
}: PropsWithChildren<DroppableProps<T>>) => {
  const { isOver, setNodeRef } = useDroppable({
    id: droppableId,
    data,
  });
  const borderStyle = isOver
    ? 'border border-dashed border-white'
    : 'border-b border-b-solid border-b-light-border border-l border-l-solid border-l-black border-l-transparent';
  return (
    <div ref={setNodeRef} className={borderStyle}>
      {children}
    </div>
  );
};
