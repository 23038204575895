import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useRedlinesContext } from '@/contexts/overview/redlines/utils';
import { ContentLayout } from '@/pages/overview/common/ContentLayout';
import RedlineDiffingContent from '@/pages/overview/redline/diffing/RedlineDiffingContent';
import { RedlineDiffingSidebar } from '@/pages/overview/redline/diffing/RedlineDiffingSidebar';
import { useRedline } from '@/pages/overview/redline/useRedline';
const RedlineDiffing = () => {
  const navigate = useNavigate();
  const redlineContext = useRedlinesContext();
  const { redlineName } = useParams();
  const { redline } = useRedline(redlineName);
  useEffect(() => {
    if (redline) {
      redlineContext?.setSelectedRedline(redline);
    } else {
      redlineContext?.setSelectedRedline(undefined);
    }
  }, [redline, redlineContext]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        navigate(`../${redlineName}/clustering`);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  });

  return <ContentLayout content={<RedlineDiffingContent />} sidebar={<RedlineDiffingSidebar />} />;
};

export default RedlineDiffing;
