interface NoFilesFoundProps {
  pageFocus: string;
}

export const NoFilesFound = ({ pageFocus }: NoFilesFoundProps) => {
  return (
    <div
      className="flex size-full flex-col items-center justify-center"
      data-testid="no-files-found-display"
    >
      <div className="flex flex-col items-center justify-center">
        <div className="text-center text-marveri-light-silver">
          <p className="processing-text ml-4 flex h-[37px] w-[305px] justify-center text-xl">
            No {pageFocus} Found
          </p>
        </div>
      </div>
    </div>
  );
};
