import LoadingIcon from '@/assets/icons/loader-icon-2.svg';

type LoadingSpinnerProps = {
  size?: number;
};

export default function LoadingSpinner({ size = 5 }: LoadingSpinnerProps) {
  const sizeCss = `w-${size} h-${size}`;

  return (
    <div className={'flex flex-row'} data-testid="loading-spinner">
      <img src={LoadingIcon} className={`${sizeCss} animate-spin`} />
    </div>
  );
}
