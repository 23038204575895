import { useEffect, useState } from 'react';

export const useContextMenu = () => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleClick = () => setIsContextMenuVisible(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return {
    isContextMenuVisible,
    setIsContextMenuVisible,
    position,
    setPosition,
  };
};
