import { trpcClient } from '@/utils/trpc';

export async function moveToCluster({
  matterNumber,
  clientNumber,
  redlineName,
  fileNames,
  toCluster,
  fromCluster,
}: {
  matterNumber: number;
  clientNumber: number;
  redlineName: string;
  fileNames: string[];
  fromCluster: string;
  toCluster: string;
}) {
  await trpcClient.redline.moveFilesBetweenClusters.mutate({
    redlineIdentifier: {
      clientNumber,
      matterNumber,
      name: redlineName,
    },
    fromCluster: fromCluster,
    toCluster: toCluster,
    fileNames,
  });
}

export async function removeFromCluster({
  matterNumber,
  clientNumber,
  redlineName,
  clusterName,
  fileName,
}: {
  matterNumber: number;
  clientNumber: number;
  redlineName: string;
  clusterName: string;
  fileName: string;
}) {
  await trpcClient.redline.removeFilesFromCluster.mutate({
    clusterIdentifier: {
      redlineIdentifier: {
        clientNumber,
        matterNumber,
        name: redlineName,
      },
      clusterName,
    },
    fileNames: [fileName],
  });
}
// Deprecated?
// export async function uploadFileToCluster({
//   matterNumber,
//   clientNumber,
//   redlineName,
//   clusterName,
//   files,
// }: {
//   matterNumber: number;
//   clientNumber: number;
//   redlineName: string;
//   clusterName?: string;
//   files: File[];
// }) {
//   if (files.length === 0) {
//     throw new Error('No files provided for upload.');
//   }
//   const existingFileNames = await trpcClient.redline.getRedline
//     .query({
//       clientNumber,
//       matterNumber,
//       name: redlineName,
//     })
//     .then((res) => res.redlineFiles.map((f) => f.file?.name || ''));
//   const redlineUploads = renameDuplicateFiles(files, existingFileNames).map((file) => {
//     return getPresignedPostAndUpload(file, clientNumber, matterNumber);
//   });

//   // Using Promise.allSettled to wait for all the uploads to either resolve or reject
//   const uploadResults = await Promise.allSettled(redlineUploads);

//   // Extract only the fulfilled promises and their values
//   const successfulUploads = uploadResults.flatMap((result) =>
//     result.status === 'fulfilled' ? [result.value] : [],
//   );

//   const processedUploads = successfulUploads.map((upload) => {
//     return {
//       name: upload.name,
//       pdfUrl: upload.url,
//       extracted_text: '',
//       average_file_embedding: [],
//     };
//   });

//   // Proceed with mutation only if there is at least one successful upload
//   if (successfulUploads.length > 0) {
//     if (clusterName) {
//       await trpcClient.redline.addRedlineFiles.mutate({
//         files: processedUploads,
//         redline_identifier: {
//           name: redlineName,
//           clientNumber: clientNumber,
//           matterNumber: matterNumber,
//         },
//       });
//       await trpcClient.redline.addFilesToCluster.mutate({
//         clusterIdentifier: {
//           redlineIdentifier: {
//             clientNumber,
//             matterNumber,
//             name: redlineName,
//           },
//           clusterName,
//         },
//         fileNames: successfulUploads.map((file) => file.name),
//       });
//     } else {
//       await trpcClient.redline.addFilesAndAutoAssignCluster.mutate({
//         files: processedUploads,
//         redline_identifier: {
//           clientNumber,
//           matterNumber,
//           name: redlineName,
//         },
//       });
//     }
//   }
// }
