import { useEffect, useState } from 'react';

interface ProcessingTextLoaderProps {
  textArray: string[];
}

export const ProcessingTextLoader = ({ textArray }: ProcessingTextLoaderProps) => {
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [textArray, textIndex]);
  return (
    <div className="m-2 animate-text-loop text-[16px] text-marveri-white">
      {textArray[textIndex]}
    </div>
  );
};
