import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

import { PageChangeEvent, Viewer } from '@react-pdf-viewer/core';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import {
  HighlightArea,
  highlightPlugin,
  RenderHighlightsProps,
  Trigger,
} from '@react-pdf-viewer/highlight';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { searchPlugin } from '@react-pdf-viewer/search';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useState } from 'react';

import ExitIcon from '@/assets/images/x-button-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';

interface SlideInPdfViewerProps {
  title: string;
  originalTitle?: string;
  selectedHighlight: number[];
  closeSlideViewer: (val: boolean) => void;
  openPdfViewer?: (val: boolean) => void;
  setSelectedRowIndex?: (val: number) => void;
}

export const SlideInPdfViewer = ({
  title,
  originalTitle,
  selectedHighlight,
  closeSlideViewer,
  openPdfViewer,
  setSelectedRowIndex,
}: SlideInPdfViewerProps) => {
  const { setSelectedFile, selectedFileDetails, selectedFileUrl } = useDataRoom();
  const [fileDisplayTitle, setFileDisplayTitle] = useState(title);
  const [animationClass, setAnimationClass] = useState('animate-slide-in');
  const [highlightedArea, setHighLightedArea] = useState<HighlightArea[]>([]);
  const [fileUrl, setFileUrl] = useState('...');
  const [pageIndex, setPageIndex] = useState(0);

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const searchPluginInstance = searchPlugin({
    enableShortcuts: true,
  });
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const rotatePluginInstance = rotatePlugin();

  const { ShowSearchPopoverButton } = searchPluginInstance;
  const {
    CurrentPageInput,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
    jumpToNextPage,
    jumpToPreviousPage,
  } = pageNavigationPluginInstance;
  const { Zoom, ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const { EnterFullScreenButton } = fullScreenPluginInstance;

  useEffect(() => {
    const newAreas = [
      {
        height: selectedHighlight[4],
        left: selectedHighlight[1],
        pageIndex: selectedHighlight[0],
        top: selectedHighlight[2],
        width: selectedHighlight[3],
      },
    ];

    setHighLightedArea(newAreas);
    setPageIndex(selectedHighlight[0] - 1);
  }, [selectedFileDetails, selectedHighlight]);

  useEffect(() => {
    if (selectedFileUrl) {
      setFileUrl(selectedFileUrl);
    }
  }, [selectedFileUrl]);

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {uniqBy(
          highlightedArea.filter((area: HighlightArea) => area.pageIndex - 1 === props.pageIndex),
          (val) => {
            return val.pageIndex + '~' + val.top;
          },
        ).map((area: HighlightArea, idx) => (
          <div
            key={idx}
            style={Object.assign(
              {},
              {
                opacity: 0.4,
                borderRadius: '5px',
                background: `#afd6e3`,
                filter: 'blur(4px)',
                zIndex: 100,
              },
              props.getCssProperties(
                {
                  height: area.height + 2,
                  left: Math.max(area.left - 1, 0),
                  pageIndex: area.pageIndex,
                  top: Math.max(area.top - 1, 0),
                  width: area.width + 4,
                },
                props.rotation,
              ),
            )}
          />
        ))}
      </div>
    );
  };

  const highlightPluginInstance = highlightPlugin({ trigger: Trigger.None, renderHighlights });
  const { jumpToHighlightArea } = highlightPluginInstance;

  useEffect(() => {
    const referencedHighlighArea: HighlightArea = {
      height: selectedHighlight[4],
      left: selectedHighlight[1],
      pageIndex: selectedHighlight[0] - 1,
      top: selectedHighlight[2] - 5,
      width: selectedHighlight[3],
    };
    jumpToHighlightArea(referencedHighlighArea);
  }, [jumpToHighlightArea, selectedHighlight]);

  const handleViewDocumentDetails = (clickedFileName: string | undefined) => {
    if (clickedFileName && openPdfViewer) {
      setSelectedFile(clickedFileName);
      openPdfViewer(true);
      closeSlideViewer(true);
    }
  };

  const handlePageChange = (e: PageChangeEvent) => {
    localStorage.setItem('current-page', `${e.currentPage}`);
  };

  const handlePdfExit = useCallback(() => {
    localStorage.setItem('current-page', '0');
    setAnimationClass('animate-slide-out');
    setTimeout(() => {
      if (setSelectedRowIndex) {
        setSelectedRowIndex(-1);
      }
      closeSlideViewer(true);
    }, 300);
  }, [closeSlideViewer, setSelectedRowIndex]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handlePdfExit();
      }
      if (event.key === 'ArrowDown') {
        jumpToNextPage();
      }
      if (event.key === 'ArrowUp') {
        jumpToPreviousPage();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handlePdfExit, jumpToNextPage, jumpToPreviousPage]);

  useEffect(() => {
    setFileDisplayTitle(title);
  }, [title]);

  return (
    <div
      className={`${animationClass} ml-4 transition-transform`}
      data-testid="slide-in-pdf-viewer"
    >
      <div
        className={`relative flex h-[70px] justify-between border-b border-b-light-border bg-container-dark p-4 text-center text-marveri-white`}
      >
        <span className="flex w-3/5 items-center break-words text-left text-[12px]">
          {fileDisplayTitle}
        </span>
        <div className="flex items-center gap-4">
          {openPdfViewer && (
            <div
              className="flex h-[40px] w-[180px] shrink-0 cursor-pointer items-center justify-center rounded-[8px] bg-marveri-light-gold text-[14px] font-semibold text-black hover:bg-marveri-gold"
              onClick={() => handleViewDocumentDetails(originalTitle)}
            >
              View Document Details
            </div>
          )}
          <MarveriIcon
            icon={ExitIcon}
            iconStyle={'h-[20px] w-[20px]'}
            iconType="other"
            onClick={handlePdfExit}
          />
        </div>
      </div>
      <div className="relative flex h-[calc(100vh-160px)] w-[calc(100vw-1500px)] min-w-[650px] items-center rounded-[5px]">
        <div className="size-full">
          <div className="flex items-center border-b border-[#363636] bg-[#b3b3b3] p-[4px]">
            <div className="flex items-center rounded-[5px]">
              <ShowSearchPopoverButton />
              <div className="rounded-[5px]">
                <GoToPreviousPage />
              </div>
              <div className="w-12 p-[2px]">
                <CurrentPageInput />
              </div>
              <CurrentPageLabel>
                {(props: RenderCurrentPageLabelProps) => (
                  <div className="flex items-center">
                    <div className="p-[2px] text-[#474747]">/ {`${props.numberOfPages}`}</div>
                  </div>
                )}
              </CurrentPageLabel>
              <div className="rounded-[5px]">
                <GoToNextPageButton />
              </div>
            </div>
            <div className="mx-auto flex items-center">
              <ZoomOutButton />
              <Zoom />
              <ZoomInButton />
            </div>
            <div className="z-10 ml-[9.2%] flex -translate-x-4">
              <EnterFullScreenButton />
            </div>
          </div>
          <Viewer
            onPageChange={handlePageChange}
            fileUrl={fileUrl}
            initialPage={pageIndex}
            defaultScale={1}
            plugins={[
              pageNavigationPluginInstance,
              searchPluginInstance,
              zoomPluginInstance,
              fullScreenPluginInstance,
              rotatePluginInstance,
              highlightPluginInstance,
            ]}
          />
        </div>
      </div>
    </div>
  );
};
