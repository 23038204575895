import * as React from 'react';

interface ContentLayoutProps {
  content: React.ReactNode;
  sidebar: React.ReactNode | null;
}

export const ContentLayout = ({ content, sidebar }: ContentLayoutProps) => {
  return (
    <>
      <div className="grow rounded-[12px]">{content}</div>
      {sidebar && <div className="w-[400px] shrink-0 bg-container-default">{sidebar}</div>}
    </>
  );
};
