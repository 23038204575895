import { useEffect, useMemo, useState } from 'react';

import { HoverText } from '@/components/HoverText';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useOverview } from '@/pages/overview/common/utils';

interface DataRoomToolButtonProps {
  name: string;
  icon: string;
  toolIdentifier: string;
  isDisabled?: boolean;
  filesAreProcessing: boolean;
  onClick: () => void;
}

export const DataRoomToolButton = ({
  name,
  icon,
  toolIdentifier,
  isDisabled,
  filesAreProcessing,
  onClick,
}: DataRoomToolButtonProps) => {
  const { toolProgress, getToolProcessing, isPanelCollapsed } = useOverview();
  const [isButtonHovered, setIsButttonHovered] = useState(false);

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const toolProcessing = useMemo(() => {
    return getToolProcessing(toolIdentifier);
  }, [getToolProcessing, toolIdentifier]);

  const generalToolProgress = toolProgress(
    toolProcessing.startTime,
    toolProcessing.finishTime,
    date,
  );

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
      setIsButttonHovered(false);
    }
  };

  return (
    <div className="relative flex justify-center">
      <div
        className={`${
          isDisabled ? 'cursor-default bg-container-default opacity-80' : 'hover:bg-N700'
        } ${toolProcessing.complete && !isDisabled ? 'cursor-pointer hover:bg-N700' : 'cursor-default'} ${isPanelCollapsed ? 'tool-resize-2-panel:w-[137px] tool-resize-3:w-[72px]' : 'tool-resize-2:w-[137px] tool-resize-3:w-[72px]'} mr-[4px] flex h-[60px] w-[200px] rounded-[8px] bg-container-default py-[20px]`}
        onClick={handleClick}
        onMouseEnter={() => setIsButttonHovered(true)}
        onMouseLeave={() => setIsButttonHovered(false)}
      >
        <div
          className={`${!toolProcessing.complete ? 'absolute left-0 top-0 z-0 h-full rounded-l-[8px]' : 'hidden'}`}
          style={{
            width: `${generalToolProgress}%`,
            backgroundColor: '#302F30',
          }}
        ></div>
        <div
          className={`${!toolProcessing.complete ? 'w-full opacity-50' : 'tool-resize-3:justify-center tool-resize-3:p-0'} relative z-10 flex w-full gap-[13px] pl-[13px] pr-[8px] `}
        >
          <img src={icon} className="size-[20px]" />
          <span
            className={`${!toolProcessing.complete && ' tool-resize-2-panel:hidden'} ${isPanelCollapsed ? 'tool-resize-3:hidden' : 'tool-resize-3:hidden'} text-[14px]`}
          >
            {name}
          </span>
          {(filesAreProcessing || !toolProcessing.complete) && (
            <div className={`absolute right-2`}>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
      {isButtonHovered ? (
        <HoverText
          isButtonHovered={isButtonHovered}
          title={name}
          isDisabled={isDisabled}
          isLoading={filesAreProcessing}
          hoverStyle="translate-y-[62px]"
        />
      ) : (
        ''
      )}
    </div>
  );
};
