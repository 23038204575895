import { useState } from 'react';

interface MarveriIconProps {
  icon: string;
  onClick?: React.MouseEventHandler;
  iconStyle: string;
  iconType: 'list-head' | 'list-main' | 'other';
}

export const MarveriIcon = ({ icon, onClick, iconStyle, iconType }: MarveriIconProps) => {
  const [isIconHovered, setIsIconHovered] = useState<boolean>(false);

  const defaultStyle = {
    filter: 'brightness(1) invert(0) sepia(0) hue-rotate(0deg)',
  };

  const hoverStyle = {
    filter:
      'brightness(0) saturate(100%) invert(99%) sepia(67%) saturate(116%) hue-rotate(30deg) brightness(110%) contrast(84%)',
  };

  const containerStyles =
    iconType === 'list-head'
      ? 'rounded-[5px] border-2 border-dark-border bg-container-dark p-1.5 hover:bg-container-hover'
      : '';

  return (
    <div
      className={`${containerStyles} shrink-0 cursor-pointer`}
      onClick={onClick}
      onMouseEnter={() => setIsIconHovered(true)}
      onMouseLeave={() => setIsIconHovered(false)}
      data-testid="marveri-icon-container"
    >
      <img
        data-testid="marveri-icon-img"
        src={icon}
        className={iconStyle}
        style={isIconHovered ? hoverStyle : defaultStyle}
      />
    </div>
  );
};
