const Dot = () => <span role="loading-dot" className="bg-[#d9d9d9]" />;

export const LoadingDots = () => (
  <div className="loader flex gap-[13px]">
    <Dot />
    <Dot />
    <Dot />
    <Dot />
  </div>
);
