import { MouseEventHandler, PropsWithChildren } from 'react';

export interface MarveriButtonProps extends PropsWithChildren {
  disabled: boolean;
  className?: string;
  onClick?: MouseEventHandler;
}

export const MarveriButton = (properties: MarveriButtonProps) => {
  const getButtonClasses = (disabled: boolean) => {
    if (disabled) {
      return 'bg-[#303030] text-[16px] font-semibold text-[#545454]';
    }

    return 'bg-marveri-white text-[#121212] cursor-pointer hover:bg-[#F8F5EE] font-semibold';
  };

  return (
    <button
      type="submit"
      disabled={properties.disabled}
      className={`flex h-[44px] w-[171px] items-center justify-center gap-[7px] rounded-[28px] px-[22px] py-[16px] duration-[0.5s] ${getButtonClasses(
        properties.disabled,
      )} ${properties.className ?? ''} `}
      onClick={properties.onClick}
      data-testid="marveri-button"
    >
      {properties.children}
    </button>
  );
};
