import classnames from 'classnames';

import { MarkupDiffElementProps } from '@/pages/overview/redline/diffing/diff/types';

export const Replace = ({
  children,
  insert: inserted,
  delete: deleted,
  relatedFileIds,
  focused,
  onPairwise,
  onMouseEnter,
  onMouseLeave,
}: MarkupDiffElementProps) => {
  return (
    <span
      className={classnames(
        'relative cursor-pointer select-none hover:rounded-lg hover:bg-[#E9D2FD]',
        {
          'text-[red]': deleted,
          'line-through': deleted,
          'text-[blue]': inserted,
          'bg-[#E9D2FD]': focused,
          'rounded-lg': focused,
        },
      )}
      onDoubleClick={() => onPairwise(relatedFileIds)}
      onMouseEnter={() => onMouseEnter(relatedFileIds, 'replace')}
      onMouseLeave={onMouseLeave}
      onMouseMove={(e) => e.stopPropagation()}
    >
      <span className="group">{children}</span>
    </span>
  );
};
