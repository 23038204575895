import classnames from 'classnames';

import { MarkupDiffElementProps } from '@/pages/overview/redline/diffing/diff/types';

export const Insert = ({
  children,
  relatedFileIds,
  focused,
  changes,
  onSelectChange,
  onPairwise,
  onMouseEnter,
  onMouseLeave,
}: MarkupDiffElementProps) => {
  return (
    <span
      className={classnames(
        [
          'relative',
          'text-[blue]',
          'group',
          'cursor-pointer',
          'select-none',
          'hover:bg-[#E9D2FD]',
          'hover:rounded-lg',
          'px-[5px]',
        ],
        {
          'bg-[#E9D2FD]': focused,
          'rounded-lg': focused,
        },
      )}
      onClick={() => changes > 0 && onSelectChange(relatedFileIds)}
      onMouseEnter={() => onMouseEnter(relatedFileIds, 'insert')}
      onMouseLeave={onMouseLeave}
      onDoubleClick={() => onPairwise(relatedFileIds)}
      onMouseMove={(e) => e.stopPropagation()}
    >
      {children}
    </span>
  );
};
