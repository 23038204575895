import { useCallback, useEffect, useState } from 'react';

import { RedlineData } from '@/common/types';
import { useOverview } from '@/pages/overview/common/utils';
import { trpcClient } from '@/utils/trpc/index';

export const useRedline = (redlineName?: string) => {
  const { matter } = useOverview();
  const [redline, setRedline] = useState<RedlineData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const getRedline = useCallback(async () => {
    if (!redlineName) return;
    setLoading(true);
    try {
      const getRedlineResponse = await trpcClient.redline.getRedline.query({
        matterNumber: matter.number,
        name: redlineName,
        clientNumber: matter.client.number,
      });
      if (
        getRedlineResponse.clusters.length === 0 &&
        getRedlineResponse.redlineFiles.every((rf) => rf.file && rf.file.hasComputedEmbedding)
      ) {
        await trpcClient.redline.generateClusters.mutate({
          name: redlineName,
          clientNumber: matter.client.number,
          matterNumber: matter.number,
        });
      }
      setRedline(getRedlineResponse);
    } finally {
      setLoading(false);
    }
  }, [matter.number, redlineName, matter.client.number]);
  useEffect(() => {
    getRedline();
  }, [matter.client.number, matter.number, redlineName, matter, getRedline]);

  return {
    redline,
    refetch: getRedline,
    loading,
  };
};
