import { useFlags } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import SupportIcon from '@/assets/icons/help-circle-icon.svg';
import FeedbackIcon from '@/assets/icons/speech-bubble-icon.svg';
import { User } from '@/common/types';
import { ENVIRONMENT } from '@/constants';
import { Settings } from '@/pages/settings/Settings';

interface UserHelpNavProps {
  isPanelCollapsed?: boolean;
  isSettingsModalOpen: boolean;
  setIsSettingsModalOpen: (val: boolean) => void;
  anchorTab?: string;
  setSettingsAnchorTag?: (val: string) => void;
  user: User;
}

export const UserHelpNav = ({
  isPanelCollapsed,
  isSettingsModalOpen,
  setIsSettingsModalOpen,
  anchorTab,
  setSettingsAnchorTag,
  user,
}: UserHelpNavProps) => {
  const flags = useFlags();

  useEffect(() => {
    if (user && user.email && ENVIRONMENT === 'production') {
      LogRocket.identify('user', {
        name: user?.firstName + ' ' + user?.lastName,
        email: user?.email || 'Unknown',
      });
    }
  }, [user]);

  const userInitials =
    user && user.firstName && user.lastName && user?.firstName[0] + user?.lastName[0];

  const handleMailtoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open('mailto:support@marveri.com', '_blank');
  };

  return (
    <div className="flex flex-col gap-4">
      {flags.feedbackButtons && (
        <div className="flex flex-col gap-4">
          <Link
            className={`${isPanelCollapsed && 'justify-center'} group flex items-center py-[6px] text-center text-[14px] font-bold text-marveri-light-silver`}
            to="#"
            onClick={handleMailtoClick}
            target="_blank"
          >
            <div className="flex gap-[10px] group-hover:filter-hover-filter">
              <img src={SupportIcon} className="size-[20px]" />
              {!isPanelCollapsed && <span>Support</span>}
            </div>
          </Link>
          <Link
            className={`${isPanelCollapsed && 'justify-center'} group flex items-center py-[6px] text-center text-[14px] font-bold text-marveri-light-silver`}
            to="https://docs.google.com/forms/d/e/1FAIpQLSfhTXCOaS1GGOOViSQL0mY41gNNRCvZ6Bhcbbo3qOyO1NNMqQ/viewform"
            target="_blank"
          >
            <div className="flex gap-[10px] group-hover:filter-hover-filter">
              <img src={FeedbackIcon} className="size-[20px]" />
              {!isPanelCollapsed && <span>Feedback</span>}
            </div>
          </Link>
        </div>
      )}
      <div
        className={`${isPanelCollapsed && 'justify-center'} group flex cursor-pointer items-center py-[6px] text-[14px] font-bold text-marveri-light-silver`}
      >
        <div className="flex gap-[10px]" onClick={() => setIsSettingsModalOpen(true)}>
          <div className="flex size-[20px] items-center justify-center rounded-full bg-marveri-silver group-hover:bg-marveri-white">
            <span className="text-[12px] text-container-dark">{userInitials}</span>
          </div>
          {!isPanelCollapsed && (
            <span className="text-[14px] group-hover:text-marveri-white">Account Settings</span>
          )}
        </div>
      </div>
      <Settings
        user={user}
        isSettingsModalOpen={isSettingsModalOpen}
        setIsSettingModalOpen={setIsSettingsModalOpen}
        anchorTab={anchorTab}
        setSettingsAnchorTag={setSettingsAnchorTag}
      />
    </div>
  );
};
