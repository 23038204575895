import { PropsWithChildren } from 'react';

import ArrowIcon from '@/assets/images/arrow-dropdown.svg';
import ClusterIcon from '@/assets/images/clusters.svg';
import RedlineIcon from '@/assets/images/redline-icon.svg';
import { Droppable } from '@/components/Droppable';
import { Cluster as ClusterType } from '@/pages/overview/redline/clustering/d3/draw-cluster';

export const Cluster = ({
  cluster,
  onRedline,
  onContextMenu,
  children,
}: PropsWithChildren<{
  cluster: ClusterType;
  onRedline: (_: string) => void;
  onContextMenu: (e: React.MouseEvent, cluster: ClusterType) => void;
}>) => {
  return (
    <details
      className="group/cluster ml-[28px]"
      key={cluster.id}
      open
      onContextMenu={(e) => onContextMenu(e, cluster)}
    >
      <summary className="flex h-[55px] cursor-pointer list-none items-center border-b border-b-light-border px-4 pb-[14px] pt-[15px] text-[12px]">
        <img
          src={ArrowIcon}
          className="mx-[5px] my-[3px] size-[16px] -rotate-90 transition group-open/cluster:rotate-0"
          data-testid="cluster-arrow-icon"
        />
        <img src={ClusterIcon} className="mx-[10px] my-[3px] size-[16px]" />
        <h1 className="w-full align-middle font-bold">Cluster {cluster.name}</h1>
        <div
          className="group mr-[10px] flex w-[320px] justify-center gap-x-2 rounded-md border border-container-hover bg-container-dark px-3 py-2 font-bold text-marveri-white shadow-sm hover:bg-container-hover [&>img]:opacity-100"
          onClick={() => onRedline(cluster.id)}
          data-testid="redline-cluster-button"
        >
          <img src={RedlineIcon} className="size-[16px]" />
          Redline this Cluster
        </div>
      </summary>
      <div className="ml-[23px] mt-3 text-black group-open/cluster:block">
        <Droppable key={cluster.id} droppableId={cluster.id} data={{ current: cluster.name }}>
          {children}
        </Droppable>
      </div>
    </details>
  );
};
