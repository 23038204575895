import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

import { ContentLayout } from '@/pages/overview/common/ContentLayout';
import { RedlineClusteringContent } from '@/pages/overview/redline/clustering/RedlineClusteringContent';
import { RedlineClusteringSidebar } from '@/pages/overview/redline/clustering/RedlineClusteringSidebar';
import { RedlineClusterList } from '@/pages/overview/redline/clustering/RedlineClusterList';
import { useRedline } from '@/pages/overview/redline/useRedline';

const RedlineClustering = () => {
  const { redlineName } = useParams();
  const flags = useFlags();
  const { redline } = useRedline(redlineName);
  if (!redline) return;
  if (flags.redlineClusterListUi) {
    return <RedlineClusterList />;
  }
  return (
    <ContentLayout
      content={flags.redlineClusterListUi ? <RedlineClusterList /> : <RedlineClusteringContent />}
      sidebar={<RedlineClusteringSidebar redline={redline} />}
    />
  );
};

export default RedlineClustering;
