import { processingText } from '@/common/utils';
import { ProcessingTextLoader } from '@/components/ProcessingTextLoader';

export const MissingDocsIdentifyLoader = () => {
  return (
    <div
      className="flex size-full flex-col items-center justify-center"
      data-testid="dataroom-loader"
    >
      <div className="flex flex-col items-center justify-center">
        <div className="text-center text-marveri-light-silver">
          <p className="processing-text ml-4 flex h-[37px] w-[305px] justify-center text-xl">
            Processing Missing Documents
          </p>
          <ProcessingTextLoader textArray={processingText} />
          <div className="mt-4 text-[14px] font-medium">
            <p className="mb-2">This may take a while.</p>
            <p>{`We will e-mail you when processing has completed.`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
