import { useState } from 'react';

import ErrorInfoIcon from '@/assets/images/information-icon-red.png';
import { FileListRedlineModal } from '@/pages/overview/redline/listing/FileListRedlineModal';

interface RedlineModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateRedlineModal = ({ isModalOpen, setIsModalOpen }: RedlineModalProps) => {
  const [redlineProcessing, setRedlineProcessing] = useState(false);
  const [showError, setShowError] = useState(false);

  // const openFileList = () => {
  //   setShowFileList(true);
  //   setIsModalOpen(false);
  // };

  return (
    <>
      {showError && (
        <div
          className="mb-4 flex items-center rounded-lg border border-red-300 bg-red-50 p-4 text-sm text-red-800 dark:border-red-800 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          <img src={ErrorInfoIcon} className="mr-3 size-5" />
          <span className="sr-only">Info</span>
          <div>
            <span className="font-medium">Unable to create redline please try again.</span>
          </div>
        </div>
      )}

      <FileListRedlineModal
        showFileList={isModalOpen}
        setShowFileList={setIsModalOpen}
        redlineProcessing={redlineProcessing}
        setRedlineProcessing={setRedlineProcessing}
        setShowError={setShowError}
      />

      {/* <SelectDocsRedlineModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        redlineProcessing={redlineProcessing}
        setRedlineProcessing={setRedlineProcessing}
        setShowError={setShowError}
        showFileList={false}
        openFileList={openFileList}
      /> */}
    </>
  );
};
