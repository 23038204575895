export const CLUSTER_CANVAS_WIDTH = 1600;
export const CLUSTER_CANVAS_HEIGHT = 600;

export const CLUSTER_RADIUS = 150;
export const CLUSTER_OFFSET = 90;
export const CLUSTER_CLEARANCE = 80;
export const CLUSTER_SHRINK_RATIO = 1.8;
export const CLUSTER_EXPAND_RATIO = 1.4;
export const CLUSTER_CENTER_OFFSET = CLUSTER_RADIUS + CLUSTER_OFFSET;

export const DOCUMENT_DISTANCE_MULTIPLIER = 100;
export const DOCUMENT_RADIUS = 10;
export const DOCUMENT_OFFSET = 25;
export const DOCUMENT_NAME_OFFSET_X = 0;
export const DOCUMENT_NAME_OFFSET_Y = 50;

export const SIMULATION_VELOCITY_DECAY = 0.2;
export const SIMULATION_ALPHA_DECAY = 0.065;
