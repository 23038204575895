import { PageChangeEvent, SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import {
  HighlightArea,
  highlightPlugin,
  RenderHighlightsProps,
  Trigger,
} from '@react-pdf-viewer/highlight';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { searchPlugin } from '@react-pdf-viewer/search';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useState } from 'react';

import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { useOverview } from '@/pages/overview/common/utils';

interface DualViewerProps {
  fileUrl: string;
}

export const DualViewer = ({ fileUrl }: DualViewerProps) => {
  const { selectedReferenceFocus, selectedRefHighlight } = useOverview();
  const { secondSelectedFileDetails } = useDataRoom();
  const [initialPageIndex, setInitialPageIndex] = useState(0);
  const searchPluginInstance = searchPlugin({
    enableShortcuts: true,
  });

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const rotatePluginInstance = rotatePlugin();

  const { ShowSearchPopoverButton } = searchPluginInstance;
  const { CurrentPageInput, GoToNextPageButton, GoToPreviousPage, CurrentPageLabel } =
    pageNavigationPluginInstance;
  const { Zoom, ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const { EnterFullScreenButton } = fullScreenPluginInstance;
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  useEffect(() => {
    if (selectedReferenceFocus === 'referencedBy') {
      setInitialPageIndex(selectedRefHighlight[0] - 1);
    }
  }, [selectedRefHighlight, selectedReferenceFocus]);

  const handlePageChange = (e: PageChangeEvent) => {
    localStorage.setItem('current-page', `${e.currentPage}`);
  };

  const defaultLocation = [0, 0, 0, 0, 0];

  const highlights =
    (secondSelectedFileDetails?.references.length || 0) > 0
      ? secondSelectedFileDetails?.references.map((ref) => ref.highlight) || [defaultLocation]
      : [defaultLocation];

  const [areas] = useState<HighlightArea[]>(
    highlights.map((highlight) => {
      return {
        height: highlight[4],
        left: highlight[1],
        pageIndex: highlight[0],
        top: highlight[2],
        width: highlight[3],
      };
    }),
  );

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <>
        {selectedReferenceFocus === 'referencedBy' && (
          <div>
            {uniqBy(
              areas.filter((area: HighlightArea) => area.pageIndex - 1 === props.pageIndex),
              (val) => {
                return val.pageIndex + '~' + val.top;
              },
            ).map((area: HighlightArea, idx) => (
              <div
                key={idx}
                style={Object.assign(
                  {},
                  {
                    opacity: 0.5,
                    borderRadius: '5px',
                    background: '#afd6e3',
                    filter: 'blur(4px)',
                    zIndex: 100,
                  },
                  props.getCssProperties(
                    {
                      height: area.height + 2,
                      left: Math.max(area.left - 1, 0),
                      pageIndex: area.pageIndex,
                      top: Math.max(area.top - 1, 0),
                      width: area.width + 4,
                    },
                    props.rotation,
                  ),
                )}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  const highlightPluginInstance = highlightPlugin({ trigger: Trigger.None, renderHighlights });

  return (
    <div className="relative ml-4 flex h-[calc(100vh-110px)] w-full items-center rounded-[5px] bg-white">
      <div className="size-full">
        <div className="flex items-center border-b border-[#363636] bg-[#b3b3b3] p-[4px]">
          <div className="flex items-center rounded-[5px]">
            <ShowSearchPopoverButton />
            <div className="rounded-[5px]">
              <GoToPreviousPage />
            </div>
            <div className="w-12 p-[2px]">
              <CurrentPageInput />
            </div>
            <CurrentPageLabel>
              {(props: RenderCurrentPageLabelProps) => (
                <div className="flex items-center">
                  <div className="p-[2px] text-[#474747]">/ {`${props.numberOfPages}`}</div>
                </div>
              )}
            </CurrentPageLabel>
            <div className="rounded-[5px]">
              <GoToNextPageButton />
            </div>
          </div>
          <div className="mx-auto flex items-center">
            <ZoomOutButton />
            <Zoom />
            <ZoomInButton />
          </div>
          <div className="ml-[9.2%] flex">
            <EnterFullScreenButton />
            <RotateForwardButton />
            <RotateBackwardButton />
          </div>
        </div>
        <Viewer
          onPageChange={handlePageChange}
          fileUrl={fileUrl || ''}
          initialPage={initialPageIndex}
          defaultScale={SpecialZoomLevel.PageWidth}
          plugins={[
            pageNavigationPluginInstance,
            searchPluginInstance,
            zoomPluginInstance,
            fullScreenPluginInstance,
            rotatePluginInstance,
            highlightPluginInstance,
          ]}
        />
      </div>
    </div>
  );
};
