import { Dialog } from '@headlessui/react';

import LoadingSpinner from '@/components/LoadingSpinner';

export type DialogViewState = 'ready' | 'submitting' | 'errored';

interface ConfirmationDialogProps {
  title: string;
  submitButtonText: string;
  isOpen: boolean;
  viewState: DialogViewState;
  onSubmit: () => void;
  closeDialog: () => void;
  onCancel: () => void;
}

export const ConfirmationDialog = ({
  title,
  submitButtonText,
  isOpen,
  viewState,
  closeDialog,
  onSubmit,
  onCancel,
}: ConfirmationDialogProps) => {
  let dialogContent;
  if (viewState === 'errored') {
    dialogContent = (
      <div data-testid="error" className="text-marveri-white">
        There was an error, please try again.
      </div>
    );
  } else if (viewState === 'submitting') {
    dialogContent = <LoadingSpinner size={20} />;
  } else {
    dialogContent = (
      <div className="flex flex-col items-center">
        <Dialog.Title className="w-[355px] text-center text-[25px] font-bold text-marveri-white">
          {title}
        </Dialog.Title>

        <div className="m-6 flex w-4/5 justify-evenly gap-10">
          <button
            type="button"
            onClick={onCancel}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={onSubmit}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-marveri-gold text-marveri-white hover:bg-marveri-light-gold"
          >
            {submitButtonText}
          </button>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      open={isOpen}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      onClose={closeDialog}
      data-testid="confirm-dialog"
    >
      <Dialog.Panel className="flex h-fit w-[454px] flex-col items-center justify-center rounded-[5px] border-2 border-light-border bg-container-dark p-4">
        {dialogContent}
      </Dialog.Panel>
    </Dialog>
  );
};
