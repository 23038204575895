interface EmptySideBarProps {
  text: string;
}

export const EmptySideBar = ({ text }: EmptySideBarProps) => {
  return (
    <div className="m-10 flex h-[90%] items-center justify-center font-bold text-marveri-white">
      NO {text} SELECTED
    </div>
  );
};
