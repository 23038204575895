import { useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

import { useDataRoom } from '@/contexts/overview/dataroom/utils';

interface DraggableDataRoomFolderProps {
  draggableId: string;
  currentDroppableId: string;
  disabled: boolean;
  isParenFolderEmpty: boolean;
  depth: number;
  isOpen: boolean;
  isFolderChecked: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const DraggableDataRoomFolder = ({
  draggableId,
  children,
  currentDroppableId,
  disabled,
  isParenFolderEmpty,
  depth,
  isOpen,
  isFolderChecked,
  onMouseEnter,
  onMouseLeave,
}: PropsWithChildren<DraggableDataRoomFolderProps>) => {
  const { newlyCreatedFolder } = useDataRoom();
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `folder://${draggableId}`,
    data: {
      current: currentDroppableId,
    },
    disabled: disabled,
  });

  return (
    <button
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={`${newlyCreatedFolder.folderPath === draggableId && 'animate-highlight-fade rounded-[4px]'} ${isParenFolderEmpty && depth > 1 ? '' : ''}  ${
        isOpen ? ' bg-N600' : 'rounded-[4px]'
      } ${isFolderChecked && 'bg-N500'}  flex w-full items-center rounded-t-[4px] bg-N600 pl-[8px] pr-[12px]`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};
