interface ProcessorErrorProps {
  customError?: string;
}

export const ProcessorError = ({ customError }: ProcessorErrorProps) => {
  return (
    <div
      className="flex size-full flex-col items-center justify-center"
      data-testid="processor-error-display"
    >
      <div className="flex flex-col items-center justify-center">
        <div className="text-center text-marveri-light-silver">
          <p className="processing-text ml-4 flex h-[37px] w-[305px] justify-center text-xl">
            {customError ? customError : 'An error occurred during processing.'}
          </p>
        </div>
      </div>
    </div>
  );
};
