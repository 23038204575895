import { useMemo } from 'react';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { COIDebugContent } from '@/pages/overview/coi-debug/content/COIDebugContent';
import { useOverview } from '@/pages/overview/common/utils';
import { MissingDocsIdentifyLoader } from '@/pages/overview/missing-signatures-debug/common/MissingDocsIdentifyLoader';

export const COIDebug = () => {
  const {
    matter: { dataRoomFiles },
  } = useOverview();

  const hasCOIData = useMemo(() => {
    const docList = dataRoomFiles
      .map((file) => {
        return file.coiData;
      }, [])
      .flat();
    return docList && docList.length > 0;
  }, [dataRoomFiles]);

  return (
    <DataRoomContextProvider>
      {hasCOIData ? <COIDebugContent /> : <MissingDocsIdentifyLoader />}
    </DataRoomContextProvider>
  );
};
