import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChangeEvent, Fragment, useEffect, useMemo, useRef, useState } from 'react';

import DropDownArrow from '@/assets/icons/small-dropdown-arrow.svg';
import { FormatTemplate, formatTemplateArray as dateFormats } from '@/pages/overview/common/utils';

interface DateDropDownProps {
  setSelectedDateFormat: (val: FormatTemplate) => void;
  hideDropDown: boolean;
}

const DateDropDown = ({ setSelectedDateFormat, hideDropDown }: DateDropDownProps) => {
  return (
    <Menu
      as="div"
      className={`${hideDropDown && 'opacity-0'}  rounded-r-[8px] bg-[#00C7BE]`}
      onPointerDown={(e) => e.stopPropagation()}
    >
      {({ open }) => (
        <div className="relative">
          <MenuButton className={'flex h-full items-center justify-center p-2'}>
            <img
              src={DropDownArrow}
              alt="Dropdown Arrow"
              className={`transition-transform duration-200 ${open ? 'rotate-90' : 'rotate-180'} size-[10px]`}
            />
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              className={
                'absolute right-0 top-[35px] z-10 w-[140px] rounded-[8px] bg-[#00C7BE] py-[5px]'
              }
            >
              {dateFormats.map((date, index) => (
                <div key={index}>
                  <MenuItem
                    as="div"
                    className="cursor-pointer rounded-[4px] px-[6px] hover:bg-[#3fb6b0]"
                    onClick={() => setSelectedDateFormat(date as FormatTemplate)}
                  >
                    {date}
                  </MenuItem>
                </div>
              ))}
            </MenuItems>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

interface DraggablePlaceholderProps {
  id: string;
  content: string;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>, targetIndex: string) => void;
  hidePlaceHolder: (content: string) => boolean;
  setFocusedInputId: (val: string) => void;
  selectedDateFormat: string;
  setSelectedDateFormat: (val: FormatTemplate) => void;
  overMe: boolean;
}

export const DraggablePlaceholder = ({
  id,
  content,
  handleOnChange,
  hidePlaceHolder,
  setFocusedInputId,
  selectedDateFormat,
  setSelectedDateFormat,
  overMe,
}: DraggablePlaceholderProps) => {
  const { attributes, listeners, setNodeRef, transition, transform } = useSortable({ id });
  //Input map to track each input width
  const [inputWidthMap, setInputWidthMap] = useState(new Map<string, number>());

  const formatContent = useMemo(() => {
    if (content.startsWith('<<date')) {
      return {
        title: selectedDateFormat,
        style: 'bg-[#00C7BE]',
        id: id,
        isInput: false,
        hasDropDown: true,
      };
    } else if (content === '<<company_name>>')
      return {
        title: 'Company Name',
        style: 'bg-[#32ADE6]',
        id: id,
        isInput: false,
        hasDropDown: false,
      };
    else if (content === '<<document_title>>')
      return {
        title: 'Document Title',
        style: 'bg-[#5856D6]',
        id: id,
        isInput: false,
        hasDropDown: false,
      };
    else if (content === '<<counterparties>>')
      return {
        title: 'Counterparties',
        style: 'bg-[#FF2D55]',
        id: id,
        isInput: false,
        hasDropDown: false,
      };
    else
      return {
        title: content,
        style: '',
        id: id,
        isInput: true,
        hasDropDown: false,
      };
  }, [content, id, selectedDateFormat]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    margin: '0 2px',
  };

  const [inputValue, setInputValue] = useState(formatContent.title);

  //Ref allows for dynamic width adjustment
  const hiddenSpanRef = useRef(new Map<string, HTMLSpanElement | null>());

  useEffect(() => {
    if (hiddenSpanRef.current.has(id)) {
      const hiddenSpan = hiddenSpanRef.current.get(id);
      if (hiddenSpan) {
        const width = hiddenSpan.offsetWidth;
        setInputWidthMap((prev) => new Map(prev).set(id, Math.max(width + 10, 10)));
      }
    }
  }, [id, inputValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, inputId: string) => {
    setInputValue(event.target.value);
    handleOnChange(event, inputId);
    const hiddenSpan = hiddenSpanRef.current.get(inputId);
    if (hiddenSpan) {
      const width = hiddenSpan.offsetWidth;
      setInputWidthMap((prev) =>
        new Map(prev).set(inputId, Math.min(Math.max(width + 10, 10), 300)),
      );
    }
  };

  const hideInput = (inputValue: string) => {
    if (inputValue === '') return true;
    return false;
  };

  return (
    <div>
      {formatContent.isInput ? (
        <div
          ref={setNodeRef}
          style={!formatContent.isInput ? style : {}}
          {...attributes}
          className={` flex flex-col items-center`}
        >
          <span
            ref={(element) => hiddenSpanRef.current.set(id, element)}
            className="invisible absolute whitespace-pre"
          >
            {inputValue || ' '}
          </span>
          <input
            className={`${hideInput(formatContent.title) && 'opacity-30 focus:opacity-100'} cursor-text rounded-[8px] border border-marveri-white text-center text-black`}
            style={{ width: `${inputWidthMap.get(id) || 10}px`, minWidth: '10px' }}
            onChange={(event) => handleInputChange(event, formatContent.id)}
            onFocus={() => setFocusedInputId(formatContent.id)}
            value={inputValue}
          />
        </div>
      ) : (
        <div className={`flex`}>
          <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            className={`${hidePlaceHolder(content) && 'opacity-0'} ${formatContent.style} flex cursor-grab flex-nowrap items-center justify-center rounded-[8px] text-[14px] font-semibold hover:opacity-90 active:cursor-grabbing ${overMe && '-translate-y-1 opacity-80'}`}
          >
            <span className="p-2"> {formatContent.title} </span>
            {formatContent.hasDropDown && (
              <div>
                <DateDropDown
                  setSelectedDateFormat={setSelectedDateFormat}
                  hideDropDown={hidePlaceHolder(content)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
