import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';

import { AppRouter } from '@/common/types';
import { MARVERI_API_HTTP_BASE_URL } from '@/constants';

export let token: string;

export function setToken(newToken: string) {
  token = newToken;
}

export const trpcBatchLink = httpBatchLink({
  url: MARVERI_API_HTTP_BASE_URL,
  maxURLLength: 5000,
  headers() {
    return {
      Authorization: `Bearer ${token}`,
    };
  },
});

export const trpcReact = createTRPCReact<AppRouter>();

// We don't need the websocket link for the vanilla client, so don't include it
export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: [trpcBatchLink],
});
