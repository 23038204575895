import { PropsWithChildren, useEffect, useState } from 'react';

import { HoverText } from '@/components/HoverText';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useOverview } from '@/pages/overview/common/utils';

interface DataRoomButtonProps {
  title: string;
  icon: string;
  onClick: () => void;
  testId: string;
  isDisabled: boolean;
  isDialogOpen?: boolean;
  filesAreProcessing?: boolean;
}

export const DataRoomButton = ({
  children,
  title,
  icon,
  onClick,
  testId,
  isDisabled,
  isDialogOpen,
  filesAreProcessing,
}: PropsWithChildren<DataRoomButtonProps>) => {
  const [isButtonHovered, setIsButttonHovered] = useState(false);
  const { isPanelCollapsed } = useOverview();

  useEffect(() => {
    setIsButttonHovered(false);
    if (isDialogOpen) {
      setIsButttonHovered(false);
    }
  }, [isDialogOpen]);

  const handleClick = () => {
    if (!isDisabled) {
      onClick();
      setIsButttonHovered(false);
    }
  };

  return (
    <div className="relative flex justify-center">
      <div
        onClick={handleClick}
        onMouseEnter={() => setIsButttonHovered(true)}
        onMouseLeave={() => setIsButttonHovered(false)}
        data-testid={testId}
        aria-disabled={isDisabled}
        className={`${
          isDisabled ? 'cursor-default bg-N700 opacity-80' : 'cursor-pointer hover:bg-N700'
        }   relative flex shrink-0 justify-center gap-x-2 rounded-[4px] bg-container-default px-3 py-2 font-bold text-marveri-white shadow-sm`}
      >
        {filesAreProcessing ? (
          <LoadingSpinner />
        ) : (
          <img src={icon} className={`${isDisabled && 'opacity-50'} size-[16px]`} />
        )}
        <span
          className={`${isPanelCollapsed ? 'tool-resize-1-panel:hidden' : 'tool-resize-1:hidden'} font-bold  ${isDisabled && 'text-[rgba(234,234,234,0.5)]'}`}
        >
          {title}
        </span>
        {children}
      </div>

      {isButtonHovered ? (
        <HoverText
          isButtonHovered={isButtonHovered}
          title={title}
          isDisabled={isDisabled}
          isLoading={filesAreProcessing}
        />
      ) : (
        ''
      )}
    </div>
  );
};
