import { MouseEventHandler, PropsWithChildren } from 'react';

export interface ButtonProperties extends PropsWithChildren {
  disabled: boolean;
  className?: string;
  onClick?: MouseEventHandler;
  icon?: string;
}

export const SimpleButton = (properties: ButtonProperties) => {
  return (
    <button
      type="submit"
      disabled={properties.disabled}
      className={`relative flex min-w-[100px] items-center justify-center gap-[7px] gap-x-2 rounded-md border border-container-hover bg-container-dark px-5 py-2 font-bold text-marveri-white shadow-sm duration-[0.5s] ${
        properties.className ?? ''
      } `}
      onClick={properties.onClick}
      data-testid="marveri-button"
    >
      {properties.icon ? <img src={properties.icon} className="size-[16px]" /> : <></>}
      {properties.children}
    </button>
  );
};
