import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

import { PageChangeEvent, Viewer } from '@react-pdf-viewer/core';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { searchPlugin } from '@react-pdf-viewer/search';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { useCallback, useEffect, useState } from 'react';

import ExitIcon from '@/assets/images/x-button-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';

interface PdfViewerProps {
  title: string;
  originalTitle?: string;
  fileUrl: string;
  closePdfViewer: (val: boolean) => void;
}

export const SimplePdfViewer = ({ title, fileUrl, closePdfViewer }: PdfViewerProps) => {
  const [fileDisplayTitle, setFileDisplayTitle] = useState(title);
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const searchPluginInstance = searchPlugin({
    enableShortcuts: true,
  });
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const rotatePluginInstance = rotatePlugin();

  const { ShowSearchPopoverButton } = searchPluginInstance;
  const {
    CurrentPageInput,
    GoToNextPageButton,
    GoToPreviousPage,
    CurrentPageLabel,
    jumpToNextPage,
    jumpToPreviousPage,
  } = pageNavigationPluginInstance;
  const { Zoom, ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const { EnterFullScreenButton } = fullScreenPluginInstance;
  const { RotateBackwardButton, RotateForwardButton } = rotatePluginInstance;

  const handlePageChange = (e: PageChangeEvent) => {
    localStorage.setItem('current-page', `${e.currentPage}`);
  };

  const handlePdfExit = useCallback(() => {
    localStorage.setItem('current-page', '0');
    closePdfViewer(true);
  }, [closePdfViewer]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handlePdfExit();
      }
      if (event.key === 'ArrowDown') {
        jumpToNextPage();
      }
      if (event.key === 'ArrowUp') {
        jumpToPreviousPage();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handlePdfExit, jumpToNextPage, jumpToPreviousPage]);

  useEffect(() => {
    setFileDisplayTitle(title);
  }, [title]);

  return (
    <div
      className="absolute left-0 top-0 z-50 flex size-full flex-col overflow-hidden bg-[#0c0c0c86]"
      data-testid="pdf-viewer"
    >
      <div
        className={`relative flex h-[70px] w-full items-center justify-center gap-4 border-b border-b-light-border bg-black p-4 text-center text-marveri-white`}
      >
        <span className="flex break-words text-left text-[12px]">{fileDisplayTitle}</span>
        <div className="absolute right-10 top-[30%] flex gap-4">
          <MarveriIcon
            icon={ExitIcon}
            iconStyle={'h-[20px] w-[20px]'}
            iconType="other"
            onClick={handlePdfExit}
          />
        </div>
      </div>
      <div className="flex size-full">
        <div className="grow" onClick={handlePdfExit}></div>
        <div className="relative flex h-[calc(100vh-110px)] w-[70%]  items-center rounded-[5px]">
          <div className="size-full">
            <div className="flex items-center border-b border-[#363636] bg-[#b3b3b3] p-[4px]">
              <div className="flex items-center rounded-[5px]">
                <ShowSearchPopoverButton />
                <div className="rounded-[5px]">
                  <GoToPreviousPage />
                </div>
                <div className="w-12 p-[2px]">
                  <CurrentPageInput />
                </div>
                <CurrentPageLabel>
                  {(props: RenderCurrentPageLabelProps) => (
                    <div className="flex items-center">
                      <div className="p-[2px] text-[#474747]">/ {`${props.numberOfPages}`}</div>
                    </div>
                  )}
                </CurrentPageLabel>
                <div className="rounded-[5px]">
                  <GoToNextPageButton />
                </div>
              </div>
              <div className="mx-auto flex items-center">
                <ZoomOutButton />
                <Zoom />
                <ZoomInButton />
              </div>
              <div className="ml-[9.2%] flex">
                <EnterFullScreenButton />
                <RotateForwardButton />
                <RotateBackwardButton />
              </div>
            </div>
            <Viewer
              onPageChange={handlePageChange}
              fileUrl={fileUrl}
              initialPage={0}
              defaultScale={1.5}
              plugins={[
                pageNavigationPluginInstance,
                searchPluginInstance,
                zoomPluginInstance,
                fullScreenPluginInstance,
                rotatePluginInstance,
              ]}
            />
          </div>
        </div>
        <div className="grow" onClick={handlePdfExit}></div>
      </div>
    </div>
  );
};
