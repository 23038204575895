import fileImg from '@/assets/images/file.png';
import FolderIcon from '@/assets/images/folder-large.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';

export const DragOverlayContent = () => {
  const { selectedFolder, checkedFiles } = useDataRoom();

  return (
    <>
      {selectedFolder ? (
        <div
          className="flex items-center gap-[8px] rounded border-2 border-light-border bg-marveri-background p-4 drop-shadow-lg"
          data-testid="data-room-folder-selection"
        >
          <img src={FolderIcon} alt="folder-icon" className="h-[18px]" />
          {selectedFolder}
        </div>
      ) : (
        <div
          className="flex items-center gap-[8px] rounded border-2 border-light-border bg-marveri-background p-4 drop-shadow-lg"
          data-testid="data-room-file-selection"
        >
          <img src={fileImg} alt="file-icon" className="h-[18px]" />
          <span>{`${checkedFiles.length} file${
            checkedFiles.length == 1 ? '' : 's'
          } selected`}</span>
        </div>
      )}
    </>
  );
};
