import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Arrow from '@/assets/images/arrow-up.svg';
import BlueFileIcon from '@/assets/images/blue-file-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import { PdfViewer } from '@/pages/pdf-viewer/PdfViewer';
import { SlideInPdfViewer } from '@/pages/pdf-viewer/SlideInPdfViewer';

type Source = {
  displayName: string;
  name: string;
};

type COIData = {
  source: Source;
  documentType: string;
  coiFilingStatus: boolean;
  coiDuplicates: boolean;
  coiDocumentType: string;
  coiReferences: string;
  coiProforma: string;
  directorInformation: string;
  directorElection: string;
  authorizedSharesStructure: string;
  formationDate: string;
  jurisdiction: string;
  address: string;
  name: string;
};

const defaultData: COIData[] = [];

const columnHelper = createColumnHelper<COIData>();

export const COIDebugContent = () => {
  const flags = useFlags();
  const { matter, setIsPanelCollapsed } = useOverview();
  const { secondSelectedFile, secondSelectedFileUrl, selectSecondFileByName } = useDataRoom();

  const { selectedFile, setSelectedFile, selectedFileUrl, dataRoomFiles } = useDataRoom();

  const [data, _setData] = useState(() => [...defaultData]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isSlideViewerOpen, setIsSlideViewerOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>();
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    source: true,
    documentType: true,
    coiFilingStatus: true,
    coiDuplicates: true,
    coiDocumentType: true,
    coiReferences: true,
    coiProforma: true,
    directorInformation: true,
    directorElection: true,
    authorizedSharesStructure: true,
    formationDate: true,
    jurisdiction: true,
    address: true,
    name: true,
  });

  const [sorting, setSorting] = useState<SortingState>([{ id: 'source', desc: false }]);

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[], rowIndex: number) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsSlideViewerOpen(true);
        setIsPanelCollapsed(true);
        setSelectedRowIndex(rowIndex);
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('source', {
        header: 'Source',
        cell: (info) => (
          <div
            className="flex cursor-pointer flex-row justify-center hover:underline"
            onClick={() => {
              selectSourceFile(info.getValue().name, [], info.row.index);
            }}
          >
            <img className="mr-4" src={BlueFileIcon} />
            {info.getValue()?.displayName}
          </div>
        ),
        footer: (info) => info.column.id,
        sortingFn: (rowA, rowB) => {
          if (
            (rowA.original.source?.displayName || '') < (rowB.original.source?.displayName || '')
          ) {
            return -1;
          } else if (
            (rowA.original.source?.displayName || '') > (rowB.original.source?.displayName || '')
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      }),
      columnHelper.accessor('documentType', {
        header: 'documentType',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coiFilingStatus', {
        header: 'coiFilingStatus',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coiDuplicates', {
        header: 'coiDuplicates',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coiDocumentType', {
        header: 'coiDocumentType',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coiReferences', {
        header: 'coiReferences',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('coiProforma', {
        header: 'coiProforma',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('directorInformation', {
        header: 'directorInformation',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('directorElection', {
        header: 'directorElection',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('authorizedSharesStructure', {
        header: 'authorizedSharesStructure',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('formationDate', {
        header: 'formationDate',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('jurisdiction', {
        header: 'jurisdiction',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('address', {
        header: 'address',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('name', {
        header: 'name',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
    ];
  }, [selectSourceFile]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility,
      sorting,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });

  useEffect(() => {
    let rows = [] as COIData[];
    rows = matter.dataRoomFiles
      .map((file) => {
        return file.coiData.map((otherData) => {
          return {
            source: {
              displayName: file.displayName,
              name: file.name,
            },
            documentType: otherData.documentType,
            coiFilingStatus: otherData.coiFilingStatus,
            coiDuplicates: otherData.coiDuplicates,
            coiDocumentType: otherData.coiDocumentType,
            coiReferences: otherData.coiReferences,
            coiProforma: otherData.coiProforma,
            directorInformation: otherData.directorInformation,
            directorElection: otherData.directorElection,
            authorizedSharesStructure: otherData.authorizedSharesStructure,
            formationDate: otherData.formationDate,
            jurisdiction: otherData.jurisdiction,
            address: otherData.address,
            name: otherData.name,
          };
        });
      })
      .flat();

    _setData(rows);
  }, [matter]);

  return (
    <>
      <div className="flex flex-col items-center bg-marveri-background text-[12px] text-marveri-white">
        <div className="w-[96%]">
          <h1 className="mt-[16px] w-full text-[34px] font-bold">All Documents</h1>
          {flags.missingDocColumnSelector && (
            <div className="my-4">
              {table.getAllColumns().map((column) => (
                <label key={column.id} className="mx-1">
                  <input
                    checked={column.getIsVisible()}
                    disabled={!column.getCanHide()}
                    onChange={column.getToggleVisibilityHandler()}
                    type="checkbox"
                  />
                  {column?.id}
                </label>
              ))}
            </div>
          )}
          <div className="w-full">
            <ScrollableDiv containerStyle="h-[calc(100vh-240px)] rounded-xl border border-[#525252] mb-2">
              <table className="min-w-full text-left text-sm font-light">
                <thead className="sticky top-0 z-10 border-b border-light-border bg-[#2E2E2E] font-medium text-[#A9A9A9]">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={`${
                            header.column.getCanSort() ? 'cursor-pointer' : ''
                          } select-none px-6 py-4`}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <div className="flex flex-row p-2">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                            <span className="flex">
                              {header.column.getIsSorted() === 'asc' ? (
                                <img className="mr-1 align-middle" src={Arrow} />
                              ) : header.column.getIsSorted() === 'desc' ? (
                                <img className="mr-1 rotate-180 align-middle" src={Arrow} />
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table
                    .getRowModel()
                    .rows.filter(() => true)
                    .map((row) => (
                      <tr
                        key={row.id}
                        className={`${
                          selectedRowIndex === row.index && 'bg-light-border'
                        } border-b border-light-border`}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="px-6 py-4 font-medium">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </ScrollableDiv>
          </div>
        </div>
      </div>
      {!!selectedFile && !!isSlideViewerOpen && (
        <SlideInPdfViewer
          title={selectedFile.displayName}
          selectedHighlight={selectedHighlight}
          originalTitle={selectedFile.name}
          closeSlideViewer={() => setIsSlideViewerOpen(false)}
          openPdfViewer={() => setIsViewerOpen(true)}
          setSelectedRowIndex={setSelectedRowIndex}
        />
      )}
      {!!selectedFile && !!isViewerOpen && !!selectedFileUrl ? (
        <PdfViewer
          title={selectedFile.displayName}
          originalTitle={selectedFile.name}
          fileUrl={selectedFileUrl}
          multipleDocuments={false}
          closePdfViewer={() => setIsViewerOpen(false)}
          highlightLocation={selectedHighlight}
          selectSecondFile={selectSecondFileByName}
          secondSelectedFile={secondSelectedFile}
          secondSelectedFileUrl={secondSelectedFileUrl}
        />
      ) : (
        <></>
      )}
    </>
  );
};
