import { useEffect, useState } from 'react';

import FileIcon from '@/assets/images/new-file-icon.svg';
import { CheckBox } from '@/components/Checkbox';
import { Draggable } from '@/components/Draggable';
import { useOverview } from '@/pages/overview/common/utils';
import { Cluster, Diff as DiffType } from '@/pages/overview/redline/clustering/d3/draw-cluster';

export const Diff = ({
  cluster,
  diff,
  hovered,
  checked,
  dropping,
  onMouseEnter,
  onMouseLeave,
  onToggle,
  onContextMenu,
  onDoubleClick,
  onClick,
}: {
  cluster: Cluster;
  diff: DiffType;
  hovered?: boolean;
  checked?: boolean;
  dropping?: boolean;
  onMouseEnter: (_: string) => void;
  onMouseLeave: (_: string) => void;
  onToggle: (_: DiffType) => void;
  onContextMenu: (e: React.MouseEvent, cluster: Cluster, diff: DiffType) => void;
  onDoubleClick: () => void;
  onClick: (difference: DiffType, displayName: string) => void;
}) => {
  const {
    matter: { dataRoomFiles },
  } = useOverview();
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const findFile = dataRoomFiles.find((file) => file.name === diff.neighbor.file?.name);
    if (findFile) {
      setDisplayName(findFile.displayName);
    } else {
      setDisplayName(diff.neighbor.file?.name || '');
    }
  }, [dataRoomFiles, diff.neighbor.file?.name]);
  if (dropping) return null;
  return (
    <Draggable draggableId={diff.id} key={diff.id}>
      <div
        className="flex h-[45px] cursor-pointer flex-row border-b border-b-light-border py-[9px]"
        key={diff.id}
        onMouseEnter={() => onMouseEnter(diff.id)}
        onMouseLeave={() => onMouseLeave(diff.id)}
        onContextMenu={(e: React.MouseEvent) => onContextMenu(e, cluster, diff)}
      >
        <CheckBox
          value={diff}
          hovered={hovered}
          checked={checked}
          onToggle={() => onToggle(diff)}
        />
        <img src={FileIcon} className="mx-[14px] my-[3px] size-[16px]" />
        <span
          className="w-full text-white"
          onDoubleClick={onDoubleClick}
          onClick={() => onClick(diff, displayName)}
        >
          {displayName}
        </span>
        <span className="w-[80px] text-white">
          {diff.neighbor.file?.name === cluster.templateFile.file?.name
            ? 'Baseline'
            : (diff.distance * 100).toFixed(0) + '%'}
        </span>
      </div>
    </Draggable>
  );
};
