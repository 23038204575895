import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const error = useRouteError();

  if (!isRouteErrorResponse(error)) {
    return <div data-testid="no-error-page"></div>;
  }

  return (
    <div
      className="flex h-screen flex-col items-center justify-center bg-black text-center text-white"
      data-testid="error-page"
    >
      <div className="flex flex-col items-center rounded bg-[#1b1b1b] p-4  text-xl">
        <p>{`Sorry, you’ve come to the wrong page.`}</p>
      </div>
      <div className="m-5 cursor-pointer rounded-[5px] bg-marveri-gold p-2 text-[20px] hover:bg-marveri-light-gold">
        <Link to="/" className="text-black">
          Return To Marveri Home
        </Link>
      </div>
    </div>
  );
};
