import { Dialog } from '@headlessui/react';

import ExitIcon from '@/assets/images/x-button-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import { trpcReact } from '@/utils/trpc';

interface FileErrorModalProps {
  isFileErrorModalOpen: boolean;
  setIsFileErrorModalOpen: (val: boolean) => void;
  erroredFiles: string[];
}

export const FileErrorModal = ({
  isFileErrorModalOpen,
  setIsFileErrorModalOpen,
  erroredFiles = [],
}: FileErrorModalProps) => {
  const updateErroredFiles = trpcReact.clientMatter.updateErroredFiles.useMutation();
  const { matter } = useOverview();

  const onClose = async () => {
    updateErroredFiles.mutate({
      number: matter.number,
      clientNumber: matter.client.number,
    });
    setIsFileErrorModalOpen(false);
  };

  return (
    <Dialog
      open={isFileErrorModalOpen}
      onClose={() => null}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50 "
    >
      <Dialog.Panel className="relative flex size-fit flex-col items-center justify-center rounded-[5px] border-2 border-dark-border bg-container-dark p-4 px-8">
        <Dialog.Title className="mb-4 w-[355px] text-center text-[25px] font-bold text-marveri-white">
          Files failed to upload
        </Dialog.Title>
        <ScrollableDiv containerStyle="h-[16rem] border-light-border border-2 rounded-[5px] bg-container-light cursor-default">
          <ul className="m-2 mx-4 list-inside list-disc">
            {erroredFiles.map((file, index) => (
              <li className="text-marveri-white" key={index + file}>
                {file.split('/').splice(-1)[0]}
              </li>
            ))}
          </ul>
        </ScrollableDiv>
        <div className="mt-4 flex w-4/5 justify-evenly">
          <button
            onClick={onClose}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-container-dark text-marveri-white hover:bg-container-hover"
          >
            Ok
          </button>
        </div>
        <MarveriIcon
          icon={ExitIcon}
          onClick={onClose}
          iconStyle="absolute top-4 right-4 h-[1.2rem]"
          iconType="other"
        />
      </Dialog.Panel>
    </Dialog>
  );
};
