import { useMemo } from 'react';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { NoFilesFound } from '@/pages/overview/common/NoFilesFound';
import { OverviewPageLoader } from '@/pages/overview/common/OverviewPageLoader';
import { useOverview } from '@/pages/overview/common/utils';
import { ContractClausesContentOld } from '@/pages/overview/contract-clauses-old/content/ContractClausesContentOld';

export const ContractClauses = () => {
  const { matter } = useOverview();

  const dataRoomFiles = useMemo(() => {
    return matter?.dataRoomFiles;
  }, [matter]);

  const hasContractDataProcessing = useMemo(() => {
    return !matter?.finishedProcessingMAClausesDataOld;
  }, [matter]);

  const hasContractData = useMemo(() => {
    const totalContracts = dataRoomFiles.flatMap((file) =>
      file.maClausesDataOld.map((contractClause) => {
        if (contractClause) {
          return Array.isArray(contractClause.maClausesOld)
            ? contractClause.maClausesOld.length
            : 0;
        } else {
          return 0;
        }
      }),
    );

    return totalContracts.some((length) => length > 0);
  }, [dataRoomFiles]);

  return (
    <DataRoomContextProvider>
      {hasContractDataProcessing ? (
        <OverviewPageLoader pageFocusTitle="Identifying Contract Clauses" />
      ) : hasContractData ? (
        <ContractClausesContentOld />
      ) : (
        <NoFilesFound pageFocus="Contract Clauses" />
      )}
    </DataRoomContextProvider>
  );
};
