import { useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

interface DraggableDataRoomFileProps {
  draggableId: string;
  currentDroppableId: string;
  disabled: boolean;
}

export const DraggableDataRoomFile = ({
  draggableId,
  children,
  currentDroppableId,
  disabled,
}: PropsWithChildren<DraggableDataRoomFileProps>) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: draggableId,
    data: {
      current: currentDroppableId,
    },
    disabled: disabled,
  });

  return (
    <div className="flex w-full items-center gap-[16px]">
      <button ref={setNodeRef} {...listeners} {...attributes} className="w-full">
        {children}
      </button>
    </div>
  );
};
