interface ReferenceTotalTagProps {
  totalReferences: number | undefined;
  containerStyle: string;
}

export const ReferenceTotalTag = ({ totalReferences, containerStyle }: ReferenceTotalTagProps) => {
  return (
    <div
      className={`${containerStyle} flex h-[18px] w-[24px] items-center justify-center rounded-[20px]`}
    >
      {totalReferences}
    </div>
  );
};
