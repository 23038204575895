import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ClusterIcon from '@/assets/images/clusters.svg';
import DataRoomIcon from '@/assets/images/data-room.svg';
import DeleteIcon from '@/assets/images/delete-icon-grey.svg';
import MoveToIcon from '@/assets/images/move-to.svg';
import RedlineIcon from '@/assets/images/redline-icon.svg';
import TagIcon from '@/assets/images/tag-icon.svg';
import { DataRoomClientFile } from '@/common/types';
import { ContextMenu as ContextMenuComponent } from '@/components/ContextMenu';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { useOverview } from '@/pages/overview/common/utils';
import { createAndSelectRedlineFiles } from '@/pages/overview/redline/utils';
import { trpcReact } from '@/utils/trpc';

interface ContextMenuProps {
  focusedFile?: DataRoomClientFile;
  xPosition: number;
  yPosition: number;
}

export const ContextMenu = ({ focusedFile, xPosition, yPosition }: ContextMenuProps) => {
  const featureFlags = useFlags();
  const { paths, checkedFiles, updateSignature } = useDataRoom();
  const { matter } = useOverview();
  const {
    matter: { folderConfig },
  } = useOverview();
  const navigate = useNavigate();

  const bulkChangePath = trpcReact.dataRoom.bulkUpdatePath.useMutation();
  const updateEmptyFolders = trpcReact.dataRoom.updateEmptyFolders.useMutation();

  interface UpdateType {
    fileName: string;
    newPath: string;
  }

  const onPathSelect = useCallback(
    (selectedArea: string) => {
      let updates = [] as UpdateType[];
      if (checkedFiles.some((file) => file.name === focusedFile?.name)) {
        checkedFiles.forEach((file) => {
          updates = updates.concat([
            {
              fileName: file.name,
              newPath: selectedArea,
            },
          ]);
        });
      }
      if (focusedFile) {
        updates = updates.concat([
          {
            fileName: focusedFile.name,
            newPath: selectedArea,
          },
        ]);
        const emptyFolders = folderConfig?.emptyFolders || [];
        if (!emptyFolders.includes(focusedFile.path)) {
          updateEmptyFolders.mutate({
            clientMatterNumber: matter.number,
            clientNumber: matter.client.number,
            emptyFolders: [...emptyFolders, focusedFile.path],
          });
        }
      }
      bulkChangePath.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        updates: updates,
      });
    },
    [
      bulkChangePath,
      checkedFiles,
      focusedFile,
      folderConfig?.emptyFolders,
      matter.client.number,
      matter.number,
      updateEmptyFolders,
    ],
  );

  const onCluster = useCallback(async () => {
    if (checkedFiles.length === 0) return;
    const redlineName = `Redline ${matter.redlines.length + 1}`;
    await createAndSelectRedlineFiles(
      checkedFiles,
      matter.number,
      matter.client.number,
      redlineName,
    );
    navigate(`../redline/${redlineName}/clustering`);
  }, [checkedFiles, matter.client.number, matter.number, matter.redlines.length, navigate]);

  const onRedline = useCallback(async () => {
    if (checkedFiles.length === 0) return;
    const redlineName = `Redline ${matter.redlines.length + 1}`;
    await createAndSelectRedlineFiles(
      checkedFiles,
      matter.number,
      matter.client.number,
      redlineName,
    );
    navigate(`../redline/${redlineName}/diff`);
  }, [checkedFiles, matter.client.number, matter.number, matter.redlines.length, navigate]);

  const onAddMissingSignatureTag = () => {
    if (focusedFile) {
      updateSignature(focusedFile.name, true);
    }
  };

  const folderPaths = useMemo(() => {
    return folderConfig?.emptyFolders || [];
  }, [folderConfig?.emptyFolders]);

  const allFolderPaths = [...paths, ...folderPaths];

  allFolderPaths.sort();

  const uniqueFolderPaths = [...new Set(allFolderPaths)];

  const contextMenuTitle = () => {
    if (checkedFiles) {
      if (checkedFiles.some((file) => file.name === focusedFile?.name)) {
        if (checkedFiles.length === 1) {
          return `${checkedFiles.length} file selected`;
        }
        return `${checkedFiles.length} files selected`;
      } else {
        return focusedFile?.displayName;
      }
    }
  };

  const innerMenuTitle = (title: string) => {
    return title.replace(/^\//, '');
  };

  return (
    <ContextMenuComponent
      title={contextMenuTitle()}
      options={[
        {
          title: 'Move To',
          icon: MoveToIcon,
          options: [
            ...(focusedFile?.path === '/'
              ? []
              : [{ title: 'Data Room', icon: DataRoomIcon, onClick: () => onPathSelect('/') }]),
            ...uniqueFolderPaths
              .filter((p) => !p.startsWith('/~Trash'))
              .filter((p) => p !== '/')
              .sort((a: string, b: string) => a.localeCompare(b))
              .map((path: string) => ({
                title: innerMenuTitle(path),
                icon: MoveToIcon,
                onClick: () => onPathSelect(path),
              })),
            {
              title: 'Trash',
              icon: DeleteIcon,
              onClick: () => onPathSelect('/~Trash'),
            },
          ],
        },
        {
          title: 'Add “Missing Signature(s)” tag',
          icon: TagIcon,
          onClick: () => onAddMissingSignatureTag(),
        },
        ...(featureFlags.redlineFeature && checkedFiles && checkedFiles.length > 1
          ? [
              {
                title: 'Redline',
                icon: RedlineIcon,
                options: [
                  {
                    title: 'Run a Redline',
                    icon: RedlineIcon,
                    onClick: onRedline,
                  },
                  {
                    title: 'Cluster',
                    icon: ClusterIcon,
                    onClick: onCluster,
                  },
                ],
              },
            ]
          : []),
      ]}
      x={xPosition}
      y={yPosition}
      data-testid="context-menu-component"
    />
  );
};
