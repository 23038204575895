import { FileWithPath } from 'file-selector';

import RejectedFileIcon from '@/assets/images/circle-file-error.svg';
import CircleFileIcon from '@/assets/images/circle-file-icon.svg';
import TrashIcon from '@/assets/images/delete-icon-grey.svg';
import { FileValidationResult } from '@/common/types';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';

interface DocumentSetUploaderListProps {
  onFileDelete: (files: File) => void;
  uploadedFiles: FileWithPath[] | undefined;
  setUploadedFiles: (files: FileWithPath[] | undefined) => void;
  rejectedFiles: FileValidationResult[]; // Use FileValidationResult for rejected files
  setRejectedFiles: (files: FileValidationResult[]) => void;
}
export const DocumentSetUploaderList = ({
  onFileDelete,
  uploadedFiles,
  setUploadedFiles,
  rejectedFiles,
  setRejectedFiles,
}: DocumentSetUploaderListProps) => {
  const deleteRejectedFile = (fileToDelete: FileWithPath) => {
    setRejectedFiles(rejectedFiles.filter(({ file }) => file.path !== fileToDelete.path));
  };

  const deleteUploadedFile = (fileToDelete: FileWithPath) => {
    const filteredUploadList = uploadedFiles?.filter(
      (currentFile) => currentFile.path !== fileToDelete.path,
    );

    setUploadedFiles(filteredUploadList);
    onFileDelete(fileToDelete);
  };

  return (
    <>
      <ScrollableDiv containerStyle="min-h-[180px] h-[264px] small:h-[180px] pr-[5px] border border-transparent">
        {rejectedFiles.map(({ file, reason }, index) => {
          if (file.path !== undefined) {
            return (
              <div
                key={index}
                className="relative my-2 flex items-center justify-between rounded-lg border border-marveri-red bg-[#4C2E29] p-[8px] text-white"
              >
                <div className="flex items-center gap-3">
                  <img src={RejectedFileIcon} />
                  <div className="flex flex-col">
                    <span>{file.path}</span>
                    <span className="text-[12px] font-bold text-marveri-red">
                      File rejected — {reason}
                    </span>
                  </div>
                </div>

                <MarveriIcon
                  icon={TrashIcon}
                  iconStyle="h-[20px]"
                  iconType="other"
                  onClick={() => deleteRejectedFile(file)}
                />
              </div>
            );
          }
        })}
        {uploadedFiles?.map((file, index) => {
          return (
            <div
              key={index}
              className="relative my-2 flex items-center justify-between rounded-lg border border-light-border p-[8px] text-white"
            >
              <div className="flex items-center gap-3">
                <img src={CircleFileIcon} />
                {file.name}
              </div>
              <MarveriIcon
                icon={TrashIcon}
                iconStyle="h-[20px]"
                iconType="other"
                onClick={() => deleteUploadedFile(file)}
              />
            </div>
          );
        })}
      </ScrollableDiv>
    </>
  );
};
