import { forwardRef, PropsWithChildren, useEffect } from 'react';

import { DataRoomClientFile } from '@/common/types';
import { NewFolderType } from '@/pages/overview/dataroom/content/common/types';

interface ScrollableDivProps {
  containerStyle?: string;
  selectedFile?: DataRoomClientFile;
  newlyCreatedFolder?: { folderPath: string; isFolderCreated: boolean };
  setNewlyCreatedFolder?: (val: NewFolderType) => void;
  onScroll?: () => void;
}

export const ScrollableDiv = forwardRef<HTMLDivElement, PropsWithChildren<ScrollableDivProps>>(
  (
    { children, containerStyle, selectedFile, newlyCreatedFolder, setNewlyCreatedFolder, onScroll },
    ref,
  ) => {
    //This useEffect contols the scroll functionality in the data room file list

    useEffect(() => {
      if (
        newlyCreatedFolder &&
        setNewlyCreatedFolder &&
        newlyCreatedFolder.isFolderCreated === true
      ) {
        setTimeout(() => {
          const selectedElement = document.getElementById(newlyCreatedFolder.folderPath);
          const container = document.getElementById('scrollable-div');
          if (selectedElement && container) {
            selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          setTimeout(() => {
            setNewlyCreatedFolder({ folderPath: '', isFolderCreated: false });
          }, 2000);
        }, 500);
      }

      if (selectedFile) {
        setTimeout(() => {
          const selectedElement = document.getElementById(selectedFile.name);
          const container = document.getElementById('scrollable-div');
          if (selectedElement && container) {
            const selectedElementPosition = selectedElement.getBoundingClientRect();
            const containerPosition = container.getBoundingClientRect();
            //Finds the position of the selected element with the parent container
            const positionWithinContainer = {
              top: selectedElementPosition.top - containerPosition.top,
              bottom: selectedElementPosition.bottom - containerPosition.top,
            };
            //Threshold can be changed to contol when to scroll
            const threshold = 0;

            if (
              positionWithinContainer.top < threshold ||
              positionWithinContainer.bottom > containerPosition.height - threshold
            ) {
              selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }
        }, 100);
      }
    }, [
      newlyCreatedFolder,
      newlyCreatedFolder?.folderPath,
      newlyCreatedFolder?.isFolderCreated,
      selectedFile,
      setNewlyCreatedFolder,
    ]);

    return (
      <div
        className={`${containerStyle} scroll-p-10 overflow-y-auto scrollbar scrollbar-thumb-[#292829] scrollbar-thumb-rounded scrollbar-w-[10px] active:scrollbar-thumb-[#302e30]`}
        data-testid="scrollable-div"
        id="scrollable-div"
        ref={ref}
        onScroll={onScroll}
      >
        {children}
      </div>
    );
  },
);

ScrollableDiv.displayName = 'ScrollableDiv';
