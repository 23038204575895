import { useCallback } from 'react';

import FolderIcon from '@/assets/images/folder-large.svg';
import Xicon from '@/assets/images/x-button-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';

export const SelectedFolderDisplay = () => {
  const {
    checkedFiles,
    setCheckedFiles,
    setIsCheckAllActive,
    changeFolderCheckStatus,
    setSelectedFolder,
    selectedFoldersArray,
    updateFolderSelection,
  } = useDataRoom();

  const handleFileDeselect = useCallback(() => {
    changeFolderCheckStatus(false);
    selectedFoldersArray.forEach((folder) => {
      updateFolderSelection(folder, true);
    });
    setCheckedFiles([]);
    setSelectedFolder('');
    setIsCheckAllActive(false);
  }, [
    changeFolderCheckStatus,
    selectedFoldersArray,
    setCheckedFiles,
    setIsCheckAllActive,
    setSelectedFolder,
    updateFolderSelection,
  ]);

  return (
    <div data-testid="selected-folder-display">
      {(selectedFoldersArray.length > 0 || checkedFiles.length > 0) && (
        <div className="flex cursor-default items-center justify-between border-b-2 border-light-border bg-container-light-gray p-4 font-bold text-marveri-white">
          <div className="flex gap-2">
            {selectedFoldersArray.length > 0 && (
              <span>
                {selectedFoldersArray.filter((folder) => folder !== '/').length} Folder
                {selectedFoldersArray.filter((folder) => folder !== '/').length > 1 ? 's' : ''},
              </span>
            )}
            <span>
              {checkedFiles.length} File{checkedFiles.length > 1 ? 's' : ''} Selected
            </span>
          </div>
          <MarveriIcon
            icon={Xicon}
            iconStyle="h-[1.2rem]"
            iconType="other"
            onClick={handleFileDeselect}
          />
        </div>
      )}
      {selectedFoldersArray.length > 0 && (
        <div>
          <ScrollableDiv containerStyle="flex flex-col border-b-[2px] border-light-border p-4 text-[15px] text-marveri-white h-full max-h-[calc(100vh-258px)] min-h-[100px]">
            {selectedFoldersArray
              .filter((folder) => folder !== '/')
              .map((folder, index) => {
                return <span key={index}>{folder.replace('/', '')}</span>;
              })}
          </ScrollableDiv>
          <div className="flex items-center justify-center border-b-2 border-light-border p-4">
            <img src={FolderIcon} className="h-20" />
          </div>
        </div>
      )}
    </div>
  );
};
