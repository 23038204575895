import { Data, useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

interface DraggableProps<T> {
  draggableId: string;
  data?: Data<T>;
  disabled?: boolean;
}

export const Draggable = <T,>({
  draggableId,
  data,
  children,
  disabled,
}: PropsWithChildren<DraggableProps<T>>) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: draggableId,
    data,
    disabled: disabled,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </div>
  );
};
