import { Dialog } from '@headlessui/react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import UploadIcon from '@/assets/images/upload-icon.svg';
import ExitIcon from '@/assets/images/x-button-icon.svg';
import { FILE_UPLOAD_ACCEPT_OBJECT } from '@/common/utils';
import LoadingSpinner from '@/components/LoadingSpinner';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';

export const RedlineClusteringUploadFileModal = ({
  title,
  isModalOpen,
  onClose,
  onUploadFile,
}: {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  onUploadFile: (file: File[]) => Promise<void>;
}) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setProcessing(true);
        await onUploadFile(acceptedFiles);
      } catch {
        setProcessing(false);
        setError(true);
      }
    },
    [onUploadFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 30,
    accept: FILE_UPLOAD_ACCEPT_OBJECT,
  });

  return (
    <Dialog
      open={isModalOpen}
      onClose={onClose}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-marveri-background/50"
    >
      <Dialog.Panel className={`flex w-[500px] flex-col rounded-[5px] bg-container-dark`}>
        {error && (
          <div
            className="mb-4 flex items-center rounded-lg border border-red-300 bg-red-50 p-4 text-sm text-red-800 dark:border-red-800 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            <svg
              className="mr-3 inline size-4 shrink-0"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div>
              <span className="font-medium"></span> Unable to add file to cluster, please try again.
            </div>
          </div>
        )}
        <div className="flex items-center justify-between border-b-2 border-b-light-border p-4">
          {processing && <LoadingSpinner />}

          <h1 className="w-full text-center font-bold text-marveri-white">{title}</h1>
          <MarveriIcon
            icon={ExitIcon}
            iconStyle={'h-[18px] w-[18px]'}
            iconType="other"
            onClick={onClose}
          />
        </div>
        <div className="m-4 flex flex-col px-[49px] py-[56px] text-marveri-light-silver">
          <div className="flex flex-col items-center text-[16px] text-marveri-white">
            <div className="pt-[29px] ">
              <div
                {...getRootProps()}
                className={`relative flex h-[146px] w-[370px] flex-col items-center justify-center rounded-lg border border-dashed
                     border-dark-border bg-container-light shadow ${
                       isDragActive ? 'border-blue-500 bg-container-selected' : ''
                     } ${processing ? 'opacity-30' : 'cursor-pointer hover:bg-container-hover'}`}
                {...(processing ? { onClick: () => {} } : {})}
              >
                <form>
                  <input {...getInputProps()} />
                </form>
                <div className="flex flex-col items-center justify-center py-[10px]">
                  <div className="flex flex-col items-center text-center">
                    <img src={UploadIcon} alt="icon" />
                    <p className="p-2 pb-[7px] text-[12px] font-bold text-[#E6E6DF]">
                      Drag & Drop Files Or
                      <span className="text-marveri-gold"> Browse</span>
                    </p>
                    <div>
                      <div>
                        <p className="text-[12px] font-bold text-[#737373]">
                          Supported formats: <br /> .docx, .doc, .pdf
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
