import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { ContentLayout } from '@/pages/overview/common/ContentLayout';
import { RightSidePanel } from '@/pages/overview/common/RightSidePanel';
import { useOverview } from '@/pages/overview/common/utils';
import { DataRoomContent } from '@/pages/overview/dataroom/content/DataRoomContent';

export const DataRoom = () => {
  const {
    matter: { fileUploadPercentComplete },
  } = useOverview();

  return (
    <DataRoomContextProvider>
      <ContentLayout
        content={<DataRoomContent />}
        sidebar={
          fileUploadPercentComplete === 100 ? <RightSidePanel pdfInteraction={false} /> : null
        }
      />
    </DataRoomContextProvider>
  );
};
