import { useMemo } from 'react';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { useOverview } from '@/pages/overview/common/utils';
import { MissingDocsIdentifyLoader } from '@/pages/overview/missing-documents-identify/common/MissingDocsIdentifyLoader';
import { MissingDocumentsIdentifyContent } from '@/pages/overview/missing-documents-identify/content/MissingDocumentsIdentifyContent';

export const MissingDocumentsIdentify = () => {
  const { matter } = useOverview();

  const hasMissingDocuments = useMemo(() => {
    return matter.finishedProcessingMissingDocs;
  }, [matter]);

  return (
    <DataRoomContextProvider>
      {hasMissingDocuments ? <MissingDocumentsIdentifyContent /> : <MissingDocsIdentifyLoader />}
    </DataRoomContextProvider>
  );
};
