import classnames from 'classnames';

import { MarkupDiffElementProps } from '@/pages/overview/redline/diffing/diff/types';

export const MoveDelete = ({
  children,
  relatedFileIds,
  focused,
  changes,
  onSelectChange,
  onPairwise,
  onMouseEnter,
  onMouseLeave,
}: MarkupDiffElementProps) => (
  <span
    className={classnames(
      [
        'relative',
        'text-[green]',
        'line-through',
        'group',
        'select-none',
        'cursor-pointer',
        'hover:bg-[#E9D2FD]',
        'hover:rounded-lg',
        'px-[5px]',
      ],
      {
        'bg-[#E9D2FD]': focused,
        'rounded-lg': focused,
      },
    )}
    onClick={() => changes > 0 && onSelectChange(relatedFileIds)}
    onDoubleClick={() => onPairwise(relatedFileIds)}
    onMouseEnter={() => onMouseEnter(relatedFileIds, 'delete')}
    onMouseLeave={onMouseLeave}
    onMouseMove={(e) => e.stopPropagation()}
  >
    {children}
  </span>
);
