import { useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';

export const DraggableScrollContainer = ({ children }: { children: React.ReactNode }) => {
  const ref = useRef<HTMLElement>(null) as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:
  return (
    <div
      className="clustering"
      data-testid="drag-scroll-container"
      {...events}
      ref={ref} // add reference and events to the wrapping div
    >
      {children}
    </div>
  );
};
