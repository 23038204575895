import { Slide, toast } from 'react-toastify';

import Xicon from '@/assets/icons/close-toast-icon.svg';

interface ToastSuccessProps {
  message: string;
}

export const showSuccessToast = (message: string) => {
  const ToastSuccess = ({ message }: ToastSuccessProps) => (
    <div className="flex h-[48px] w-fit rounded-[5px] bg-[#1B7F37] py-[12px] pl-[20px] pr-[12px] text-[14px] font-normal text-white">
      <span className="mr-[10px]">{message}</span>
      <div className="mr-[8px] h-[24px] border bg-white opacity-20" />
      <img src={Xicon} className="hover:filter-hover-filter" />
    </div>
  );
  toast(<ToastSuccess message={message} />, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
    style: { animationDuration: '0.6s' },
    className: 'custom-toast-container',
  });
};
