import { useMemo } from 'react';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { NoFilesFound } from '@/pages/overview/common/NoFilesFound';
import { OverviewPageLoader } from '@/pages/overview/common/OverviewPageLoader';
import { ProcessorError } from '@/pages/overview/common/ProcessorError';
import { useOverview } from '@/pages/overview/common/utils';
import { MissingDocumentsContent } from '@/pages/overview/missing-documents/content/MissingDocumentsContent';

export const MissingDocuments = () => {
  const { matter } = useOverview();

  const missingDocsProcessing = useMemo(() => {
    return !matter.finishedProcessingMissingDocs;
  }, [matter]);

  const hasMissingDocs = useMemo(() => {
    return matter.dataRoomFiles.some(
      (file) => file.mentions.length > 0 || file.mentionedBy.length > 0,
    );
  }, [matter.dataRoomFiles]);

  const missingDocsSuccessStatus = useMemo(() => {
    return matter?.lastMissingDocsStatus;
  }, [matter]);

  return (
    <DataRoomContextProvider>
      {missingDocsProcessing ? (
        <OverviewPageLoader pageFocusTitle="Processing Missing Documents" />
      ) : hasMissingDocs ? (
        <MissingDocumentsContent />
      ) : missingDocsSuccessStatus ? (
        <NoFilesFound pageFocus="Missing Documents" />
      ) : (
        <ProcessorError />
      )}
    </DataRoomContextProvider>
  );
};
