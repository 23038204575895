import { Dialog } from '@headlessui/react';

interface AuthenticatorModalProps {
  isAuthenticatorModalOpen: boolean;
  setIsAuthenticatorModalOpen: (val: boolean) => void;
}
export const AuthenticatorModal = ({
  isAuthenticatorModalOpen,
  setIsAuthenticatorModalOpen,
}: AuthenticatorModalProps) => {
  return (
    <Dialog
      open={isAuthenticatorModalOpen}
      onClose={() => setIsAuthenticatorModalOpen(false)}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50 "
      data-testid="authenticator-modal"
    >
      <Dialog.Panel className="flex h-fit w-[454px] flex-col items-center justify-center rounded-[5px] border-2 border-dark-border bg-container-dark p-4">
        <Dialog.Title className="w-[355px] text-center text-[25px] font-bold text-marveri-white">
          AUTHENTICATOR APP COMING SOON
        </Dialog.Title>
        <div className="m-6 flex w-4/5 justify-evenly">
          <button
            onClick={() => setIsAuthenticatorModalOpen(false)}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
          >
            Close
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
