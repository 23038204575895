import { Dialog, DialogPanel } from '@headlessui/react';
import { DataRoomFile } from '@server/modules/client-matter/common/types';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Arrow from '@/assets/images/arrow-up.svg';
import InformationIcon from '@/assets/images/information-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import { MaClausesDataOld } from '@/pages/overview/contract-clauses/common/utils';
import { SlideInPdfViewer } from '@/pages/pdf-viewer/SlideInPdfViewer';

const deafultContractData: MaClausesDataOld[] = [];

const columnHelper = createColumnHelper<MaClausesDataOld>();

export const ContractClausesContentOld = () => {
  const { matter, setIsPanelCollapsed, isPanelCollapsed } = useOverview();
  const { selectedFile, setSelectedFile, dataRoomFiles } = useDataRoom();
  const [isSlideViewerOpen, setIsSlideViewerOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);
  const [selectedCell, setSelectedCell] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<number>();
  const [data, setContractData] = useState(() => [...deafultContractData]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'sourceDocument_displayName', desc: false },
  ]);

  const flags = useFlags();

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[], cellId: string, rowIndex: number) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect && highlight.length > 0) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsSlideViewerOpen(true);
        setIsPanelCollapsed(true);
        setSelectedCell(cellId);
        setSelectedRow(rowIndex);
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  const createContractClauseColumn = useCallback(
    (key: string, header: string) =>
      columnHelper.accessor((row) => row.maClauses.find((c) => c.category === key), {
        header,
        enableSorting: true,
        sortingFn: (rowA, rowB, columnId) => {
          const valueA = rowA.getValue(columnId) as { response?: string };
          const valueB = rowB.getValue(columnId) as { response?: string };

          const responseA = valueA?.response || '';
          const responseB = valueB?.response || '';

          return responseA.localeCompare(responseB);
        },
        cell: (info) => {
          return (
            <div
              onClick={() => {
                selectSourceFile(
                  info.row.original.sourceDocument.name,
                  info?.getValue()?.locations || [],
                  info.cell.id,
                  info.row.index,
                );
              }}
              className="p-4"
            >
              <p>{info?.getValue()?.response}</p>
              {flags.missingDocColumnSelector ? (
                <>
                  <br />
                  <p>{info?.getValue()?.citation}</p>
                </>
              ) : (
                <></>
              )}
            </div>
          );
        },
      }),
    [flags.missingDocColumnSelector, selectSourceFile],
  );

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('sourceDocument.displayName', {
        header: 'Document Name',
        enableSorting: true,
        sortingFn: 'alphanumeric',
        cell: (info) => {
          return (
            <div
              onClick={() => {
                selectSourceFile(
                  info.row.original.sourceDocument.name,
                  [0, 0, 0, 0, 0],
                  info.cell.id,
                  info.row.index,
                );
              }}
              className="p-4"
            >
              <p>{info.getValue()}</p>
            </div>
          );
        },
      }),
      createContractClauseColumn('description', 'Description'),
      createContractClauseColumn('expiration_date', 'Expiration Date'),
      createContractClauseColumn('renewal_term', 'Renewal Period'),
      createContractClauseColumn('notice_to_terminate_renewal', 'Notice to Terminate Renewal'),
      createContractClauseColumn('termination_for_convenience', 'Termination for Convenience'),
      createContractClauseColumn('anti_assignment', 'Anti-Assignment'),
      createContractClauseColumn('change_of_control', 'Change of Control'),
      createContractClauseColumn('non_compete', 'Non-Compete'),
      createContractClauseColumn('exclusivity', 'Exclusivity'),
      createContractClauseColumn('no_solicit_of_customers', 'Non-Solicit of Customers'),
      createContractClauseColumn('rofo_rofr_rofn', 'ROFO/ ROFR/ ROFN'),
      createContractClauseColumn('most_favored_nation', 'Most Favored Nation'),
      createContractClauseColumn('governing_law', 'Governing Law'),
    ];
    return cols;
  }, [createContractClauseColumn, selectSourceFile]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  useEffect(() => {
    let rows = [] as MaClausesDataOld[];
    rows = matter.dataRoomFiles
      .map((file) => {
        return file.maClausesDataOld.map((contractClause) => {
          return {
            id: contractClause.id,
            sourceDocument: file as DataRoomFile,
            maClauses: contractClause.maClausesOld.map((clause) => {
              return {
                category: clause.category,
                response: clause.response,
                citation: clause.citation,
                locations: clause.locations,
              };
            }),
          };
        });
      })
      .flat();
    setContractData(rows);
  }, [matter.dataRoomFiles]);

  const getCellLocation = (cellFocus: MaClausesDataOld | unknown, columnId: string) => {
    if (columnId === 'sourceDocument_displayName') {
      return true;
    }

    if (
      typeof cellFocus === 'object' &&
      cellFocus !== null &&
      'locations' in cellFocus &&
      Array.isArray((cellFocus as { locations: number[] }).locations)
    ) {
      const clauseLocation = (cellFocus as { locations: number[] }).locations;
      return clauseLocation.length > 0;
    } else {
      return null;
    }
  };

  const closeSlideInViewer = useCallback(() => {
    setSelectedCell('');
    setIsSlideViewerOpen(false);
  }, []);

  const closeDialog = useCallback(() => {
    setIsInfoDialogOpen(false);
  }, []);

  const handleSortToggle = (columnId: string) => {
    setSorting((currentSorting) => {
      const existingSort = currentSorting.find((sort) => sort.id === columnId);
      if (!existingSort) {
        return [{ id: columnId, desc: false }];
      }
      return [{ id: columnId, desc: !existingSort.desc }];
    });
  };

  return (
    <>
      <div
        className={`flex flex-col items-center rounded-[12px] bg-marveri-background text-[12px] text-marveri-white`}
        data-testid="contract-clause-content"
      >
        <div className="w-[96%]">
          <div className="flex gap-2">
            <h1 className="mt-[16px] text-[34px] font-bold">Contract Clauses</h1>
            <img
              src={InformationIcon}
              onClick={() => setIsInfoDialogOpen(true)}
              className="size-5 translate-y-[2.2rem] cursor-pointer"
            />
          </div>
          <div className="mr-[36px] mt-[36px] w-full" data-testid="contract-clause-content-list">
            <ScrollableDiv
              containerStyle={`${isSlideViewerOpen ? 'w-[calc(100vw-765px)]' : isPanelCollapsed ? 'w-[calc(100vw-160px)]' : 'w-[calc(100vw-350px)]'} h-[calc(100vh-160px)] overflow-x-auto rounded-xl border border-[#525252] mb-2 transition-all duration-300`}
            >
              <table className="min-w-full text-left text-sm font-light">
                <thead className="sticky top-0 z-10 border-b border-light-border bg-[#2E2E2E] font-medium text-[#A9A9A9]">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={`${
                            header.column.getCanSort() ? 'cursor-pointer' : ''
                          } select-none py-4`}
                          onClick={() => handleSortToggle(header.column.id)}
                        >
                          <div className="flex flex-row p-2">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                            <span className="flex">
                              {header.column.getIsSorted() === 'asc' ? (
                                <img className="mr-1 align-middle" src={Arrow} />
                              ) : header.column.getIsSorted() === 'desc' ? (
                                <img className="mr-1 rotate-180 align-middle" src={Arrow} />
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={`border-b border-light-border hover:bg-[#191919] ${
                        selectedRow === row.index && 'bg-[#191919]'
                      }`}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={`${getCellLocation(cell.getValue(), cell.column.id) ? 'cursor-pointer font-medium hover:ring-2 hover:ring-inset hover:ring-marveri-white' : 'cursor-default'} ${selectedCell === cell.id && 'ring-2 ring-inset ring-marveri-white'}`}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ScrollableDiv>
          </div>
        </div>
      </div>
      <Dialog
        open={isInfoDialogOpen}
        onClose={closeDialog}
        className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50 "
      >
        <DialogPanel className="flex w-[630px] flex-col items-center justify-center rounded-[12px] border-2 border-dark-border bg-container-dark p-4 text-marveri-white">
          <ScrollableDiv containerStyle="h-[calc(100vh-300px)]">
            <p className="mr-[15px]">
              Marveri deploys a variety of AI models to identify and review every commercial
              contract uploaded, in order to identify the following clauses that could be implicated
              in a merger or other corporate transaction:
              <br></br>
              <br></br>
              <h1>Expiration Date:</h1> On what date does the agreement expire by its terms?
              <br></br>
              <br></br>
              <h1>Renewal Term:</h1> What is the renewal term that will apply following the
              expiration of the agreement, whether by automatic extension or unilateral extension by
              notice (if any)?
              <br></br>
              <br></br>
              <h1>Notice to Terminate Renewal:</h1> What is the notice period required in order to
              terminate any automatic (or unilateral) renewal of the agreement (if applicable)?
              <br></br>
              <br></br>
              <h1>Termination for Convenience:</h1> Is a party given the right to terminate the
              agreement solely by giving notice, without any need for cause or the satisfaction of
              other conditions (other than the passage of time / end of a waiting period)?
              <br></br>
              <br></br>
              <h1>Anti-Assignment:</h1> Does the agreement require a party to obtain consent from,
              or provide notice to, another party (or parties) in connection with assigning the
              agreement to a third party?
              <br></br>
              <br></br>
              <h1>Change of Control:</h1> Does the agreement require a party to obtain consent from,
              or provide notice to, another party (or parties) in connection with a change of
              control of such party? Does the agreement permit a counterparty to terminate the
              agreement in the event of such a corporate transaction? (Note the system will treat
              restrictions on assignment by operation of law as a change of control restriction).
              <br></br>
              <br></br>
              <h1>Non-Compete:</h1> Does the agreement restrict a party from operating or competing
              with a counterparty in a specific geographic area, or a specific business or
              technology sector?
              <br></br>
              <br></br>
              <h1>Exclusivity:</h1> Does the agreement impose a requirement (whether during and/or
              following the term) that a party exclusively deal with a counterparty? (Note the
              system will flag as relevant: prohibitions on licensing or selling to third parties;
              prohibitions on collaboration or working with third parties; or “requirements”
              contracts requiring a party to procure all of its requirements for certain goods or
              services)
              <br></br>
              <br></br>
              <h1>Customer Non-Solicit:</h1> Does the agreement restrict a party from contracting
              with or soliciting customers or partners of a counterparty (whether during and/or
              following the term)?
              <br></br>
              <br></br>
              <h1>Right of First Offer / Refusal / Negotiation:</h1> Does the agreement grant one
              party a right of first refusal, right of first offer or right of first negotiation to
              purchase, license, market or distribute equity interests, technology, assets, products
              or services?
              <br></br>
              <br></br>
              <h1>Most Favored Nation:</h1>Does the agreement require that, in the event a party
              provides a third party with better terms on the sale / licensing of technology / goods
              / services described in the contract, the counter-party under the contract must be
              entitled to receive the same or better terms?
              <br></br>
              <br></br>
              <h1>Governing Law:</h1> What jurisdiction’s law governs the interpretation of the
              contract?
              <br></br>
              <br></br>Carefully review all AI output. Marveri generally meets (or exceeds) the
              accuracy of human reviewers; however, the output will not be perfect, and the mistakes
              made by Marveri may not be the same or similar to those which could occur in a human
              review. Do not rely on Marveri&apos;s output as necessarily detecting all relevant
              clauses, whether listed above or not, that you may need to identify as part of a legal
              review.
            </p>
            <div className="mt-4">
              <button
                className="mx-2 h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
                onClick={closeDialog}
              >
                OK
              </button>
            </div>
          </ScrollableDiv>
        </DialogPanel>
      </Dialog>
      {!!selectedFile && !!isSlideViewerOpen && (
        <SlideInPdfViewer
          title={selectedFile.displayName}
          selectedHighlight={selectedHighlight}
          originalTitle={selectedFile.name}
          closeSlideViewer={closeSlideInViewer}
        />
      )}
    </>
  );
};
