import { NewDiligenceWizardProvider } from '@/contexts/create-new-diligence-wizard/NewDiligenceWizardContext';
import { HomeContent } from '@/pages/home/content/HomeContent';

export const Home = () => {
  return (
    <NewDiligenceWizardProvider>
      <HomeContent />
    </NewDiligenceWizardProvider>
  );
};
