import { Dialog } from '@headlessui/react';
import { useState } from 'react';

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants';
import PrivacyPolicyDoc from '@/docs/Marveri Privacy Policy.pdf';
import TermsOfUseDoc from '@/docs/User Terms of Service.pdf';
import { SimplePdfViewer } from '@/pages/pdf-viewer/SimplePdfViewer';
import { AuthenticatorModal } from '@/pages/settings/security/AuthenticatorModal';
import { trpcReact } from '@/utils/trpc';

export const Security = () => {
  const [isAuthenticatorModalOpen, setIsAuthenticatorModalOpen] = useState(false);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
  const user = trpcReact.user.getCurrentUser.useQuery().data;

  const handlePrivacyOptionClick = (title: string, file: string) => {
    setSelectedPdf(file);
    setPdfTitle(title);
    setIsPdfViewerOpen(true);
  };

  const handleUpdatePassword = async () => {
    const data = {
      client_id: AUTH0_CLIENT_ID,
      email: user?.email,
      connection: 'Username-Password-Authentication',
    };
    const response = await fetch(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (response.ok) {
      setIsPasswordResetModalOpen(true);
    }
  };

  return (
    <div data-testid="security-page">
      <h1 className="mb-[32px] text-[18px] font-semibold">Security & Privacy</h1>
      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[4px]">
          <span className="text-[12px] font-bold text-[#868686]">Password</span>
          <div
            className="flex h-[34px] w-fit cursor-pointer items-center justify-center rounded-[4px] border border-[#A9A9A9] p-[12px] font-semibold text-marveri-white hover:bg-[#292829]"
            onClick={handleUpdatePassword}
          >
            Send password reset e-mail
          </div>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[12px] font-bold text-[#868686]">Two-Factor Authentification</span>
          <div
            className="flex h-[34px] w-fit cursor-pointer items-center justify-center rounded-[4px] border border-[#A9A9A9] p-[12px] font-semibold text-marveri-white hover:bg-[#292829]"
            onClick={() => setIsAuthenticatorModalOpen(true)}
          >
            Authenticator app
          </div>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[12px] font-bold text-[#868686]">Privacy Policies</span>
          <div
            className="flex h-[34px] w-fit cursor-pointer items-center justify-center rounded-[4px] border border-[#A9A9A9] p-[12px] font-semibold text-marveri-white hover:bg-[#292829]"
            onClick={() => handlePrivacyOptionClick('Marveri Privacy Policy', PrivacyPolicyDoc)}
            data-testid="privacy-policy-button"
          >
            Open
          </div>
        </div>
        <div className="flex flex-col gap-[4px]">
          <span className="text-[12px] font-bold text-[#868686]">Terms of Use</span>
          <div
            className="flex h-[34px] w-fit cursor-pointer items-center justify-center rounded-[4px] border border-[#A9A9A9] p-[12px] font-semibold text-marveri-white hover:bg-[#292829]"
            onClick={() => handlePrivacyOptionClick('Marveri Terms of Use', TermsOfUseDoc)}
            data-testid="terms-of-use-button"
          >
            Open
          </div>
        </div>
      </div>
      <AuthenticatorModal
        isAuthenticatorModalOpen={isAuthenticatorModalOpen}
        setIsAuthenticatorModalOpen={setIsAuthenticatorModalOpen}
      />
      {isPdfViewerOpen && (
        <SimplePdfViewer
          title={pdfTitle}
          fileUrl={selectedPdf}
          closePdfViewer={() => setIsPdfViewerOpen(false)}
        />
      )}
      {isPasswordResetModalOpen && (
        <Dialog
          open={isPasswordResetModalOpen}
          onClose={() => setIsPasswordResetModalOpen(false)}
          className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
        >
          <Dialog.Panel className="flex h-fit w-[454px] flex-col items-center justify-center rounded-[5px] border-2 border-light-border bg-container-dark p-4">
            <Dialog.Title className="w-[355px] text-center text-[25px] font-bold text-marveri-white">
              Reset Password Link Sent
            </Dialog.Title>
            <p className="w-[355px] pb-2 text-center text-[15px] text-marveri-white">
              You can reset your password by following the secure link in the e-mail recently sent
              to you.
            </p>
            <br />
            <button
              className="h-[38px] w-[100px] rounded border border-dark-border bg-marveri-gold text-marveri-white hover:bg-marveri-light-gold"
              onClick={() => setIsPasswordResetModalOpen(false)}
            >
              OK
            </button>
          </Dialog.Panel>
        </Dialog>
      )}
    </div>
  );
};
