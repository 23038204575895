import { useMemo } from 'react';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { useOverview } from '@/pages/overview/common/utils';
import { MissingDocsIdentifyLoader } from '@/pages/overview/missing-signatures-debug/common/MissingDocsIdentifyLoader';
import { MissingSignaturesDebugContent } from '@/pages/overview/missing-signatures-debug/content/MissingSignaturesDebugContent';

export const MissingSignaturesDebug = () => {
  const {
    matter: { dataRoomFiles },
  } = useOverview();

  const hasMissingDocuments = useMemo(() => {
    const docList = dataRoomFiles
      .map((file) => {
        return file.otherSignatureData;
      }, [])
      .flat();
    return docList && docList.length > 0;
  }, [dataRoomFiles]);

  return (
    <DataRoomContextProvider>
      {hasMissingDocuments ? <MissingSignaturesDebugContent /> : <MissingDocsIdentifyLoader />}
    </DataRoomContextProvider>
  );
};
