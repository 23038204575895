import { useNavigate } from 'react-router-dom';

import WarningIcon from '@/assets/images/warning.svg';

export const ErrorContent = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex size-full flex-col items-center justify-center px-[16px]"
      data-testid="error-content"
    >
      <img src={WarningIcon} className="mb-[17px] size-[50px]" />
      <div className="flex flex-col items-center justify-center text-marveri-light-silver">
        <div>We&apos;ve run into an error. You will need</div>
        <div>
          to create a new client matter{' '}
          <a className="cursor-pointer underline" onClick={() => navigate('/home')}>
            here.
          </a>
        </div>
        <div>Before doing so, please ensure you are</div>
        <div>only uploading allowed document types</div>
        <div>that are not corrupted or too large.</div>
      </div>
    </div>
  );
};
