import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';

import LogoutIcon from '@/assets/images/logout-icon.svg';

interface LogoutModalProps {
  isLogoutModalOpen: boolean;
  setIsLogoutModalOpen: (val: boolean) => void;
}

export const LogoutModal = ({ isLogoutModalOpen, setIsLogoutModalOpen }: LogoutModalProps) => {
  const { logout } = useAuth0();

  const onLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
    window.location.href = '/';
  };

  return (
    <Dialog
      open={isLogoutModalOpen}
      onClose={() => setIsLogoutModalOpen(false)}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50 "
      data-testid="logout-modal"
    >
      <Dialog.Panel className="flex h-fit w-[454px] flex-col items-center justify-center rounded-[5px] border-2 border-dark-border bg-container-dark p-4">
        <img src={LogoutIcon} className="m-4" />
        <Dialog.Title className="w-[355px] text-center text-[25px] font-bold text-marveri-white">
          Are you sure you want to Logout?
        </Dialog.Title>
        <div className="m-6 flex w-4/5 justify-evenly">
          <button
            onClick={() => setIsLogoutModalOpen(false)}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
          >
            Cancel
          </button>
          <button
            onClick={onLogout}
            className="h-[38px] w-[100px] rounded border border-dark-border bg-container-dark text-marveri-white hover:bg-container-hover"
          >
            Yes
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
