import { useCallback, useEffect, useState } from 'react';

import { ACCEPTED_EXTENSIONS } from '@/common/utils';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';

interface RenameListItemFormProps {
  onClose: () => void;
  onSubmit: (newName: string) => void;
  currentName: string;
  renameInputRef?: React.RefObject<HTMLInputElement>;
  setRenameFileIndex?: (val: number) => void;
}

export const RenameListItemForm = ({
  onClose,
  onSubmit,
  currentName,
  renameInputRef,
  setRenameFileIndex,
}: RenameListItemFormProps) => {
  const [newListItemName, setNewListItemName] = useState(currentName);

  const { combinedDocumentArray, setSelectedFile, updateRenameFormStatus } = useDataRoom();

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const renameItemComponent = document.getElementById('rename-input-form');
      const dataRoomButtons = document.querySelector('[data-testid="data-room-buttons"]');
      const headerCheckBox = document.querySelector('[data-testid="data-room-header-checkbox"]');
      const dataRoomSidebar = document.querySelector('[data-testid="right-panel-sidebar"]');
      const pdfViewer = document.querySelector('[data-testid="pdf-viewer"]');
      const clientMatterNameContainer = document.querySelector(
        '[data-testid="rename-client-name-form"]',
      );
      if (
        renameItemComponent?.contains(event.target as Node) ||
        dataRoomButtons?.contains(event.target as Node) ||
        headerCheckBox?.contains(event.target as Node) ||
        dataRoomSidebar?.contains(event.target as Node) ||
        pdfViewer?.contains(event.target as Node) ||
        clientMatterNameContainer?.contains(event.target as Node)
      )
        return;
      else {
        setSelectedFile('');
        updateRenameFormStatus(false);
        onSubmit(newListItemName);
      }
    },
    [newListItemName, onSubmit, setSelectedFile, updateRenameFormStatus],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, newListItemName, onSubmit, setSelectedFile, updateRenameFormStatus]);

  const getCurrentFileIndex = useCallback(
    (currentFileName: string) => {
      const currentFileIndex = combinedDocumentArray.findIndex(
        (dataRoomFile) => dataRoomFile.displayName === currentFileName,
      );
      if (setRenameFileIndex) {
        setRenameFileIndex(currentFileIndex);
      }
    },
    [combinedDocumentArray, setRenameFileIndex],
  );

  const getFileExtention = useCallback((fileName: string) => {
    const foundExtention = ACCEPTED_EXTENSIONS.find((extention) => fileName.includes(extention));
    if (foundExtention) return foundExtention.length;
  }, []);

  useEffect(() => {
    if (currentName) {
      getCurrentFileIndex(currentName);
      const fileExtensionLength = getFileExtention(currentName);
      if (renameInputRef && renameInputRef.current && fileExtensionLength) {
        renameInputRef.current.focus();
        renameInputRef.current.setSelectionRange(0, currentName.length - fileExtensionLength);
      }
    }
  }, [currentName, getCurrentFileIndex, getFileExtention, renameInputRef]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewListItemName(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <form
      className={`flex w-full items-center justify-between rounded-[5px] border border-[#4E9FFF] bg-marveri-background text-marveri-white`}
      onSubmit={() => onSubmit(newListItemName)}
      onClick={(e) => e.stopPropagation()}
      data-tesid="rename-input-form"
      id="rename-input-form"
    >
      <input
        type="text"
        className={`h-[35px] w-full rounded-[5px] bg-inherit indent-2 outline-none`}
        onChange={handleChange}
        value={newListItemName}
        onKeyDown={handleKeyDown}
        ref={renameInputRef}
      />
    </form>
  );
};
