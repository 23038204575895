import LoadingSpinner from '@/components/LoadingSpinner';

interface LoaderWithTextProps {
  text: string;
  containerStyle: string;
}

export const LoaderWithText = ({ text, containerStyle }: LoaderWithTextProps) => {
  return (
    <div className={containerStyle}>
      <LoadingSpinner size={6} /> <span>{text}</span>
    </div>
  );
};
