interface HomeHoverText {
  isButtonHovered: boolean;
  toolCount: number;
  fileCount: number;
}

export const HomeHoverText = ({ isButtonHovered, toolCount, fileCount }: HomeHoverText) => {
  let displayText = '';

  if (toolCount < 1 && fileCount < 1) {
    displayText = 'Upload files and add tools';
  } else if (toolCount > 0 && fileCount < 1) {
    displayText = 'Upload files';
  } else if (toolCount < 1 && fileCount > 0) {
    displayText = 'Add a tool';
  } else if (toolCount > 0 && fileCount > 0) {
    return null;
  }

  return displayText ? (
    <div
      className={`${isButtonHovered ? 'block' : 'hidden'} absolute left-1/2 z-20 w-fit -translate-x-1/2 translate-y-[38px]  whitespace-nowrap rounded-[5px] border border-container-hover bg-container-dark p-[4px] text-center text-xs font-normal text-marveri-white`}
    >
      <span>{displayText}</span>
    </div>
  ) : null;
};
